import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import { InputText } from '@/components/InputText';
import { InputFileSupport } from '@/features/support/components/InputFileSupport';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { FaPaperPlane, FaRegUser, FaSyncAlt } from 'react-icons/fa';
import './style.css';
import {
  FormInputMessagem,
  FormUpdateSupportStatus,
  ShouldShowModalReverse,
  States,
} from '@/features/support/types';
import Support from '@/model/Support';
import MessageType from '@/model/MessageType';
import SenderType from '@/model/SenderType';
import { Loading, SelectCustom } from '@/components';
import { updateMask } from '@/helpers/masks/cpf';
import SupportType from '@/model/SupportType';
import { ClientInfo } from '@/features/support/components/ClientInfo';
import OrderSite from '@/model/OrderSite';
import IconCloseCircle from '@/assets/images/svg/close';
// import { ClientInfo } from '@/features/support/components/ClientInfo';

interface StateProps {
  state: States;
  support?: Support;
  formData: FormData;
  formErrors: FormErrors;
  formDataStatus: FormData;
  formErrorsStatus: FormErrors;
  currentPage: OrderSite[];
  order?: OrderSite;
  visible: boolean;
  modal: ShouldShowModalReverse;
  title: string | React.ReactNode;
}

interface OptionProps {
  value: string;
  label: string;
}

interface DispatchProps {
  onChangeInput: OnChangeFormInput;
  onChangeFileInput: (e: EventTarget & HTMLInputElement) => void;
  onClearImage: () => void;
  onSendMessage: () => void;
  onUpdateStatusConfirm: () => void;
  onChangeFormInput: OnChangeFormInput;
  onShouldShowModal: (
    value: ShouldShowModalReverse,
    newTitleModal: string | React.ReactNode,
    orderSelected?: OrderSite,
  ) => void;
  onShowReverseOrder: (orderSelected: OrderSite) => void;
  onShowCancelOrder: (orderSelected: OrderSite) => void;
  onToggle: () => void;
}

type Props = StateProps & DispatchProps;

export const ChatUI: React.FC<Props> = ({
  state,
  support,
  formData,
  formErrors,
  formDataStatus,
  formErrorsStatus,
  currentPage,
  order,
  visible,
  modal,
  title,
  onChangeInput,
  onChangeFileInput,
  onClearImage,
  onSendMessage,
  onChangeFormInput,
  onUpdateStatusConfirm,
  onShouldShowModal,
  onShowReverseOrder,
  onShowCancelOrder,
  onToggle,
}): JSX.Element => {
  const imageBase64Ref = React.useRef<HTMLInputElement>(null);
  const disableInput = (): boolean => {
    if (formData.file && String(formData.file).trim().length > 0) {
      return true;
    }
    return false;
  };

  const isPDF = (content: string): boolean => {
    const split = content.split('.');
    const extension = split[split.length - 1];
    if (extension.toUpperCase() === 'PDF') {
      return true;
    }
    return false;
  };

  const getType = (supportType: SupportType): string => {
    let value = '';
    if (supportType === SupportType.CANCELLATION) {
      value = 'Cancelamento';
    } else if (supportType === SupportType.PAYMENTS) {
      value = 'Pagamento';
    } else if (supportType === SupportType.TICKET) {
      value = 'Ingresso';
    } else if (supportType === SupportType.ACCOUNT) {
      value = 'Conta';
    } else if (supportType === SupportType.OTHERS) {
      value = 'Outros';
    }
    return value;
  };

  const optionsSelect: OptionProps[] = [
    { value: 'Reaberto', label: 'Abrir Novamente' },
    { value: 'Aguradando Cliente', label: 'Aguardando Cliente' },
    { value: 'Cancelado', label: 'Cancelar' },
    { value: 'Fechado', label: 'Fechar' },
    { value: 'Resolvido', label: 'Foi Resolvido' },
  ];

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSendMessage();
    }
  };

  return (
    <>
      <Loading isVisible={state === States.loading} />
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between">
          <h5 className="pageTitle">Atendimento</h5>
        </div>
      </Container>

      <div className="main-chat">
        <div className="container-chat">
          <div className="messages-container">
            {support &&
              support.messages &&
              support.messages.length > 0 &&
              support.messages.map((message, index) => (
                <Fragment key={`${index}-message-${message.id}`}>
                  {message.messageType === MessageType.TEXT ? (
                    <div
                      key={index}
                      className={`message ${
                        message.senderType !== SenderType.CLIENT ? 'sender-user' : 'sender-other'
                      }`}
                    >
                      <span>{message.comment}</span>
                    </div>
                  ) : (
                    <Fragment>
                      {isPDF(message.comment) ? (
                        <div
                          key={index}
                          className={`message ${
                            message.senderType !== SenderType.CLIENT
                              ? 'sender-user'
                              : 'sender-other'
                          }`}
                        >
                          <pre>
                            <iframe
                              src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${message.comment}#toolbar=0&scrollbar=0`}
                              style={{ width: '100%', height: '100%' }}
                              frameBorder="0"
                            ></iframe>
                          </pre>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className={`message ${
                            message.senderType !== SenderType.CLIENT
                              ? 'sender-user'
                              : 'sender-other'
                          }`}
                        >
                          <img src={message.comment} alt="Imagem selecionada" />
                        </div>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              ))}
          </div>
          <div className="flex-container">
            <div style={{ display: 'flex', position: 'relative' }}>
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                {formData.file && (
                  <button type="button" onClick={onClearImage} className="clearImage">
                    <IconCloseCircle />
                  </button>
                )}
                <InputText
                  type="text"
                  name="sendmessage"
                  value={formData[FormInputMessagem.message]}
                  onChange={e => onChangeInput(FormInputMessagem.message)(e.target.value)}
                  error={formErrors.message && formErrors.message[0]}
                  className="input-text"
                  disabled={disableInput()}
                  keyDow={e => onKeyDown(e)}
                />
              </div>
              <InputFileSupport
                refInput={imageBase64Ref}
                name="file"
                onChange={e => onChangeFileInput(e.target)}
                error={formErrors.file && formErrors.file[0]}
              />
            </div>
            <a
              href="#"
              className="button-send"
              onClick={() => {
                onSendMessage();
              }}
            >
              <FaPaperPlane className="icon" />
            </a>
          </div>
        </div>
        <div className="container-user">
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center', fontSize: '20px' }}>
            <FaRegUser /> Dados do cliente
          </div>
          <div className="client-info">
            <div className="client-info-item">
              <span className="label-chat">Protocolo:</span>
              <span className="value">{support?.protocol}</span>
            </div>
            <div className="client-info-item">
              <span className="label-chat">Categoria:</span>
              <span className="value">{support ? getType(support.supportType) : ''}</span>
            </div>
            <div className="client-info-item">
              <span className="label-chat">Nome:</span>
              <span className="value">{support?.name}</span>
            </div>
            <div className="client-info-item">
              <span className="label-chat">CPF:</span>
              <span className="value">{support && support.cpf ? updateMask(support.cpf) : ''}</span>
            </div>
            <div className="client-info-item">
              <span className="label-chat">Email:</span>
              <span className="value">{support?.email}</span>
            </div>
            <div className="client-info-item">
              <span className="label-chat">Alerta de Fralde:</span>
              <span className="value">
                {support && support.client && support.client.fraudAlert ? 'Possui' : 'Não possui'}
              </span>
            </div>
            {/* 
            <div className="client-info-item">
              <div className="client-info">
                <span className="value">Array com os eventos comprados</span>
              </div>
            </div>
            */}
            <div className="status-container">
              <SelectCustom
                name="supportStatus"
                label="Mudar Status do atendimento"
                options={optionsSelect}
                placeholder={formDataStatus[FormUpdateSupportStatus.status]}
                value={formDataStatus[FormUpdateSupportStatus.status]}
                onChange={e => {
                  onChangeFormInput(FormUpdateSupportStatus.status)(e?.value as string);
                }}
                error={formErrorsStatus.type && formErrorsStatus.type[0]}
              />
              <button onClick={() => onUpdateStatusConfirm()} className="update-button">
                <FaSyncAlt />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ClientInfo
          state={state}
          currentPage={currentPage}
          onShouldShowModal={onShouldShowModal}
          title={title}
          visible={visible}
          onToggle={onToggle}
          modal={modal}
          order={order}
          onShowReverseOrder={onShowReverseOrder}
          onShowCancelOrder={onShowCancelOrder}
        />
      </div>
    </>
  );
};
