import { AUTH_ROUTES } from '@/features/auth/navigation';
import { ARTIST_ROUTES } from '@/features/artist/navigation';
import { EVENTS_BRACELET_ROUTES } from '@/features/bracelet/navigation';
import { CANCEL_REQUEST_ROUTES } from '@/features/cancelRequest/navigation';
import { CARD_FEE_ROUTES } from '@/features/cardFee/navigation';
import { CLIENT_ROUTES } from '@/features/client/navigation';
import { COMBO_ROUTES } from '@/features/combo/navigation';
import { COMPANY_ROUTES } from '@/features/contractor/navigation';
import { CREATE_SALE_ROUTES } from '@/features/createSale/navigation';
import { DASHBOARD_ROUTES } from '@/features/dashboard/navigation';
import { DOCUMENT_ROUTES } from '@/features/document/navigation';
import { EMAIL_ROUTES } from '@/features/email/navigation';
import { EVENTS_ROUTES } from '@/features/events/navigation';
import { FINANCIAL_ROUTES } from '@/features/financial/navigation';
import { FINISHED_EVENTS_ROUTES } from '@/features/finishedEvents/navigation';
import { FINISHED_EVENT_DETAIL_ROUTES } from '@/features/finishedEventDetail/navigation';
import { GROUPSUBGROUPPRODUCT_ROUTES } from '@/features/groupSubgroupProduct/navigation';
import { LOG_ROUTES } from '@/features/log/navigation';
import { MENU_ROUTES } from '@/features/menu/screens/navigation';
import { MODULE_ROUTES } from '@/features/module/screens/navigation';
import { PAYMENT_ROUTES } from '@/features/paymentMethods/navigation';
import { PAYMENTGATEWAY_ROUTES } from '@/features/paymentGateway/navigation';
import { PDV_ROUTES } from '@/features/pdv/navigation';
import { PERMISSION_ROUTES } from '@/features/permission/screens/navigation';
import { POS_ROUTES } from '@/features/pos/navigation';
import { PRODUTCT_ROUTES } from '@/features/product/navigation';
import { PRODUTCTSCOMBOS_ROUTES } from '@/features/productsAndCombos/navigation';
import { REGISTEREVENT_ROUTES } from '@/features/registerEvent/navigation';
import { RELEASE_EVENT_ROUTES } from '@/features/releaseEvent/navigation';
import { SUB_MENU_ROUTES } from '@/features/submenu/screens/navigation';
import { TICKET_ROUTES } from '@/features/ticket/navigation';
import { REPORTS_ROUTES } from '@/features/reports/navigation';
import { USER_ROUTES } from '@/features/usersAndGroups/screens/navigation';
import { EVENTCLOSE_ROUTES } from '@/features/eventClose/navigation';
import { EVENTS_VALIDATOR_ROUTES } from '@/features/eventValidator/navigation';
import { EVENTS_MAP_ROUTES } from '@/features/eventMap/navigation';
import { REVERSE_ROUTES } from '@/features/reverse/navigation';
import { REPRINT_ROUTES } from '@/features/reprint/navigation';
import { SUPPORT_ROUTES } from '@/features/support/navigation';
import { ACQUIRING_FEE_ROUTES } from '@/features/acquiringFee/navigation';
import { PRODUCER_REPORT_ROUTES } from '@/features/producerReport/navigation';
import { REDEMPTION_ROUTES } from '@/features/redemption/navigation';
import { REDEMPTION_FINISHED_ROUTES } from '@/features/redemptionFinished/navigation';
import { STATEMENT_ROUTES } from '@/features/statement/navigation';
import { TABLE_BLOCK_ROUTES } from '@/features/tableBlock/navigation';

const path = {
  Initial: {
    ...AUTH_ROUTES,
    All: '*',
  },
  Dashboard: {
    ...DASHBOARD_ROUTES,
    Bracelet: {
      ...EVENTS_BRACELET_ROUTES,
    },
    Events: {
      ...EVENTS_ROUTES,
    },
    FinishedEvents: {
      ...FINISHED_EVENTS_ROUTES,
    },
    FinishedEventDetail: {
      ...FINISHED_EVENT_DETAIL_ROUTES,
    },
    Event: {
      ...REGISTEREVENT_ROUTES,
    },
    ReleaseEvent: {
      ...RELEASE_EVENT_ROUTES,
    },
    Ticket: {
      ...TICKET_ROUTES,
    },
    Reports: {
      ...REPORTS_ROUTES,
    },
    UsersGroups: {
      itself: '/dashboard/users-groups',
      edit: '/dashboard/users-groups/edit/:id',
    },
    Clients: {
      ...CLIENT_ROUTES,
    },
    Module: {
      ...MODULE_ROUTES,
    },
    Permission: {
      ...PERMISSION_ROUTES,
    },
    Menu: {
      ...MENU_ROUTES,
    },
    SubMenu: {
      ...SUB_MENU_ROUTES,
    },
    CardFee: {
      ...CARD_FEE_ROUTES,
    },
    AcquiringFee: {
      ...ACQUIRING_FEE_ROUTES,
    },
    Email: {
      ...EMAIL_ROUTES,
    },
    User: {
      ...USER_ROUTES,
    },
    Pdv: {
      ...PDV_ROUTES,
    },
    Pos: {
      ...POS_ROUTES,
    },
    Gateway: {
      ...PAYMENTGATEWAY_ROUTES,
    },
    GroupSubgroupProduct: {
      ...GROUPSUBGROUPPRODUCT_ROUTES,
    },
    Combo: {
      ...COMBO_ROUTES,
    },
    Producer: {
      ...PRODUCER_REPORT_ROUTES,
    },
    Product: {
      ...PRODUTCT_ROUTES,
    },
    Payment: {
      ...PAYMENT_ROUTES,
    },
    ProductsCombos: {
      ...PRODUTCTSCOMBOS_ROUTES,
    },
    Company: {
      ...COMPANY_ROUTES,
    },
    Artist: {
      ...ARTIST_ROUTES,
    },
    EventValidator: {
      ...EVENTS_VALIDATOR_ROUTES,
    },
    EventMap: {
      ...EVENTS_MAP_ROUTES,
    },
    EventClose: {
      ...EVENTCLOSE_ROUTES,
    },
    Document: {
      ...DOCUMENT_ROUTES,
    },
    Log: {
      ...LOG_ROUTES,
    },
    CancelRequest: {
      ...CANCEL_REQUEST_ROUTES,
    },
    ReverseRequest: {
      ...REVERSE_ROUTES,
    },
    Support: {
      ...SUPPORT_ROUTES,
    },
    ReprintRequest: {
      ...REPRINT_ROUTES,
    },
    Redemption: {
      ...REDEMPTION_ROUTES,
    },
    RedemptionFinished: {
      ...REDEMPTION_FINISHED_ROUTES,
    },
    Statement: {
      ...STATEMENT_ROUTES,
    },
    CreateSaleRequest: {
      ...CREATE_SALE_ROUTES,
    },
    Financial: {
      ...FINANCIAL_ROUTES,
    },
    TableBlock: {
      ...TABLE_BLOCK_ROUTES,
    },
  },
} as const;

export { path };
