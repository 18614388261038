import React from 'react';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { Col, FormGroup, Row } from 'reactstrap';
import { TextArea } from '@/components';
import { FormInputRefuse } from '../../types';

interface FilterContentProps {
  formData: FormData;
  formErrors: FormErrors;
  onChangeFormInput: OnChangeFormInput;
}

export const RefuseContent: React.FC<FilterContentProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
}): JSX.Element => (
  <Row>
    <Col md={8}>
      <h5 className="mb-5 border-bottom-title">Recusar resgate</h5>
      <FormGroup className="mb-2">
        <TextArea
          name="refusedReason"
          label="Informe o motivo"
          placeholder="Ex:  Valor não permitido"
          maxLength={100}
          value={formData[FormInputRefuse.refusedReason]}
          onChange={e => onChangeFormInput(FormInputRefuse.refusedReason)(e.target.value)}
          error={formErrors.refusedReason && formErrors.refusedReason[0]}
        />
      </FormGroup>
    </Col>
  </Row>
);
