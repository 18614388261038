import { TableColumn } from 'react-data-table-component';

// eslint-disable-next-line no-shadow
export enum FormInputFilter {
  startDate = 'startDate',
  endDate = 'endDate',
  eventId = 'eventId',
  type = 'type',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  filter = 'filter',
  detailRequest = 'detailRequest',
  detailManual = 'detailManual',
}

interface DataRow {
  id: string;
  eventName: string;
  description: string;
  type: string;
  date: string;
  value: string;
  actions: string;
}

export const columns: TableColumn<DataRow>[] = [
  {
    name: 'Evento',
    selector: row => row.eventName,
    width: '300px',
  },
  {
    name: 'Descrição',
    selector: row => row.description,
    width: '300px',
  },
  {
    name: 'Tipo',
    selector: row => row.type,
    width: '300px',
  },
  {
    name: 'Data',
    selector: row => row.date,
    width: '170px',
  },
  {
    name: 'Valor',
    selector: row => row.value,
    width: '100px',
  },
  {
    name: 'Ações',
    selector: row => row.actions,
  },
];
