import React, { useEffect, useState } from 'react';
import { States } from '@/helpers/common/states';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import Event from '@/model/Event';
import api from '@/services/api';
import EventSiteGet from '@/model/EventSiteGet';
import CheckDiscount from '@/model/CheckDiscount';
import Cart from '@/model/Cart';
import CartEvent from '@/model/CartEvent';
import PaymentMethod from '@/model/PaymentMethod';
import CartEventTicket from '@/model/CartEventTicket';
import CreateOrderAdmResponse from '@/model/CreateOrderAdmResponse';
import Page from '@/model/Page';
import OrderSite from '@/model/OrderSite';
import EventMap from '@/model/EventMap';
import ChartObject from '@/model/ChartObject';
import ChartSelectedTicketType from '@/model/ChartSelectedTicketType';
import validators from '@/helpers/validators';
import { updateMask as cpfUpdateMask } from '@/helpers/masks/cpf';
import { updateMask as phoneUpdateMask } from '@/helpers/masks/mobilePhone';
import { updateMask as dateUpdateMask } from '@/helpers/masks/generalDate';
import CartEventTicketUserData from '@/model/CartEventTicketUserData';
import dayjs from 'dayjs';
import { SelectedObject } from '@/model/SelectedObject';
import { CreateSaleContainer } from './ui';
import {
  FormInputName,
  OnShouldShowModalProps,
  ShouldShowModal,
  UserDataFormInputName,
} from '../types';

export const CreateSaleScreen: React.FC = (): JSX.Element => {
  const [state, setStatus] = useState<States>(States.default);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.detail);
  const [currentPage, setCurrentPage] = useState<Page<OrderSite, OrderSite>>({
    page: 1,
    pageSize: 10,
  } as Page<OrderSite, OrderSite>);
  const [events, setEvents] = useState<Event[]>([]);
  const [event, setEvent] = useState<EventSiteGet>();
  const [eventMap, setEventMap] = useState<EventMap>();
  const [eventKey, setEventKey] = useState<string>();
  const [amount, setAmount] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [fee, setFee] = useState<number>(0);
  const [discount, setDiscount] = useState<CheckDiscount>();
  const [totalWithFee, setTotalWithFee] = useState<number>(0);
  const [selectedObjects, setSelectedObjects] = useState<SelectedObject[]>([]);
  const [selectedChartObject, setSelectedChartObject] = useState<ChartObject>();
  const [deselectedChartObject, setDeselectedChartObject] = useState<ChartObject>();
  const [disableName, setDisableName] = useState<boolean>(false);
  const [disableEmail, setDisableEmail] = useState<boolean>(false);
  const [disablePhone, setDisablePhone] = useState<boolean>(false);
  const [disableBirthDate, setDisableBirthDate] = useState<boolean>(false);
  const [disableMotherName, setDisableMotherName] = useState<boolean>(false);
  const [orderResponse, setOrderResponse] = useState<CreateOrderAdmResponse>();
  const [order, setOrder] = useState<OrderSite>();
  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const handleOnGetOrders = async (page: Page<OrderSite, OrderSite>): Promise<void> => {
    try {
      setStatus(States.loading);
      const { data } = await api.post<Page<OrderSite, OrderSite>>('/order/page', page);

      if (data) {
        setCurrentPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(States.default);
    }
  };

  const handleOnGetEvents = async (): Promise<void> => {
    try {
      setStatus(States.loading);
      const { data } = await api.get<Event[]>(`/order/event`);
      setEvents(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(States.default);
    }
  };

  const handleOnGetEvent = async (eventId: string): Promise<void> => {
    try {
      setStatus(States.loading);
      const { data } = await api.get<EventSiteGet>(`/order/event/${eventId}`);
      setEvent(data);
      try {
        const response = await api.get<EventMap>(`/table/map/${eventId}`);
        setEventMap(response.data);
      } catch {
        window.console.log('No map');
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(States.default);
    }
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      eventId: '',
      sectionId: '',
      code: '',
      paymentForm: 'site',
      showUserData: 'false',
    },
  });

  const formControllerUserData = useForm({
    initialData: {
      name: '',
      document: '',
      email: '',
      phone: '',
      birthDate: '',
      motherName: '',
    },
    validators: {
      name: [validators.required],
      document: [validators.required, validators.cpf],
      email: [validators.required],
      birthDate: [validators.required, validators.birthday],
      motherName: [validators.required],
    },
    formatters: {
      document: cpfUpdateMask,
      phone: phoneUpdateMask,
      birthDate: dateUpdateMask,
    },
  });

  const handleOnAddTicket = (
    ticketId: string,
    isHalfPrice: boolean,
    section?: string,
    label?: string,
  ): void => {
    let newTotalValue = 0;
    let newFee = 0;
    let checkSection = formData[FormInputName.sectionId];
    if (section) {
      checkSection = section;
    }
    if (event && event.sections && event.sections.length > 0) {
      const newEvent = {
        ...event,
      } as EventSiteGet;
      // eslint-disable-next-line no-plusplus
      for (let s = 0; s < newEvent.sections.length; s++) {
        if (newEvent.sections[s].section.id === checkSection) {
          if (newEvent.sections[s].tickets && newEvent.sections[s].tickets.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let t = 0; t < newEvent.sections[s].tickets.length; t++) {
              if (
                newEvent.sections[s].tickets[t].id === ticketId &&
                newEvent.sections[s].tickets[t].isHalfPrice === isHalfPrice
              ) {
                if (newEvent.sections[s].tickets[t].amount) {
                  const amountTicket = (newEvent.sections[s].tickets[t].amount as number) + 1;
                  newEvent.sections[s].tickets[t].amount = amountTicket;
                } else {
                  newEvent.sections[s].tickets[t].amount = 1;
                }
                newTotalValue += Number(newEvent.sections[s].tickets[t].unitValue);
                let ticketFee = 0;
                if (
                  newEvent.sections[s].tickets[t].fee &&
                  newEvent.sections[s].tickets[t].fee > 0
                ) {
                  ticketFee =
                    Number(newEvent.sections[s].tickets[t].unitValue) *
                      Number(newEvent.sections[s].tickets[t].fee) -
                    Number(newEvent.sections[s].tickets[t].unitValue);
                  newFee += ticketFee;
                }
                newEvent.sections[s].tickets[t].totalValue =
                  (Number(newEvent.sections[s].tickets[t].unitValue) + ticketFee) *
                  Number(newEvent.sections[s].tickets[t].amount);

                if (label) {
                  let lables = newEvent.sections[s].tickets[t].lables as string[];
                  if (lables && lables.length > 0) {
                    lables.push(label);
                    newEvent.sections[s].tickets[t].lables = lables;
                  } else {
                    lables = [];
                    lables.push(label);
                    newEvent.sections[s].tickets[t].lables = lables;
                  }
                }
                break;
              }
            }
          }
          break;
        }
      }
      setEvent(newEvent);
      setAmount(amount + 1);
      setTotalValue(totalValue + newTotalValue);
      setFee(fee + newFee);
      setTotalWithFee(totalWithFee + newTotalValue + newFee);
    }
  };

  const handleOnChartObjectSelected = (
    object: ChartObject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selectedTicketType: ChartSelectedTicketType,
  ): void => {
    setSelectedChartObject(object);
  };

  const handleOnSelectChartObjectSelected = (): void => {
    if (selectedChartObject && eventMap) {
      const newSelectedObject: SelectedObject = {
        label: selectedChartObject.label,
        ticketType: selectedChartObject.selectedTicketType,
      };
      const newSelectedObjects: SelectedObject[] = [];
      let existsSeletedObject = false;
      selectedObjects.forEach(data => {
        if (
          data.label === newSelectedObject.label &&
          data.ticketType === newSelectedObject.ticketType
        ) {
          existsSeletedObject = true;
        }
        newSelectedObjects.push(data);
      });
      if (!existsSeletedObject) {
        newSelectedObjects.push(newSelectedObject);
        setSelectedObjects(newSelectedObjects);
        if (eventMap.sections && eventMap.sections.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let s = 0; s < eventMap.sections.length; s++) {
            if (eventMap.sections[s].section.name === selectedChartObject.category.label) {
              if (eventMap.sections[s].tickets && eventMap.sections[s].tickets.length > 0) {
                // eslint-disable-next-line no-plusplus
                for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
                  if (
                    eventMap.sections[s].tickets[t].ticket.name ===
                    selectedChartObject.labels.section
                  ) {
                    handleOnAddTicket(
                      eventMap.sections[s].tickets[t].ticket.id,
                      false,
                      eventMap.sections[s].section.id,
                      selectedChartObject.label,
                    );
                  }
                }
              }
              break;
            }
          }
        }
      }
    }
  };

  const handleOnRemoveTicket = (
    ticketId: string,
    isHalfPrice: boolean,
    section?: string,
    label?: string,
  ): void => {
    let newTotalValue = 0;
    let newFee = 0;
    let checkSection = formData[FormInputName.sectionId];
    if (section) {
      checkSection = section;
    }
    if (event && event.sections && event.sections.length > 0) {
      const newEvent = {
        ...event,
      } as EventSiteGet;
      // eslint-disable-next-line no-plusplus
      for (let s = 0; s < newEvent.sections.length; s++) {
        if (newEvent.sections[s].section.id === checkSection) {
          if (newEvent.sections[s].tickets && newEvent.sections[s].tickets.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let t = 0; t < newEvent.sections[s].tickets.length; t++) {
              if (
                newEvent.sections[s].tickets[t].id === ticketId &&
                newEvent.sections[s].tickets[t].isHalfPrice === isHalfPrice
              ) {
                if (newEvent.sections[s].tickets[t].amount) {
                  const amountTicket = (newEvent.sections[s].tickets[t].amount as number) - 1;
                  newEvent.sections[s].tickets[t].amount = amountTicket;
                } else {
                  newEvent.sections[s].tickets[t].amount = 0;
                }
                newTotalValue = Number(newEvent.sections[s].tickets[t].unitValue);
                let ticketFee = 0;
                if (
                  newEvent.sections[s].tickets[t].fee &&
                  newEvent.sections[s].tickets[t].fee > 0
                ) {
                  ticketFee =
                    Number(newEvent.sections[s].tickets[t].unitValue) *
                      Number(newEvent.sections[s].tickets[t].fee) -
                    Number(newEvent.sections[s].tickets[t].unitValue);
                  newFee = ticketFee;
                }
                newEvent.sections[s].tickets[t].totalValue =
                  (Number(newEvent.sections[s].tickets[t].unitValue) + ticketFee) *
                  Number(newEvent.sections[s].tickets[t].amount);
                if (label) {
                  let index = 0;
                  const labels = newEvent.sections[s].tickets[t].lables as string[];
                  // eslint-disable-next-line no-plusplus
                  for (let l = 0; l < labels.length; l++) {
                    if (label === labels[l]) {
                      index = l;
                      break;
                    }
                  }
                  labels.splice(index, 1);
                  newEvent.sections[s].tickets[t].lables = labels;
                }
                break;
              }
            }
          }
          break;
        }
      }
      setEvent(newEvent);
      setAmount(amount - 1);
      setTotalValue(totalValue - newTotalValue);
      setFee(fee - newFee);
      setTotalWithFee(totalWithFee - (newTotalValue + newFee));
    }
  };

  const handleOnChartObjectDeselected = (
    object: ChartObject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedTicketType: ChartSelectedTicketType,
  ): void => {
    setDeselectedChartObject(object);
  };

  const handleOnChartObjectRemoveTicket = (): void => {
    if (deselectedChartObject && eventMap) {
      const newSelectedObject: SelectedObject = {
        label: deselectedChartObject.label,
        ticketType: deselectedChartObject.selectedTicketType,
      };
      const newSelectedObjects: SelectedObject[] = [];
      let existsSeletedObject = false;
      selectedObjects.forEach(data => {
        if (
          data.label === newSelectedObject.label &&
          data.ticketType === newSelectedObject.ticketType
        ) {
          existsSeletedObject = true;
        } else {
          newSelectedObjects.push(data);
        }
      });
      if (existsSeletedObject) {
        setSelectedObjects(newSelectedObjects);
        if (eventMap.sections && eventMap.sections.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let s = 0; s < eventMap.sections.length; s++) {
            if (eventMap.sections[s].section.name === deselectedChartObject.category.label) {
              if (eventMap.sections[s].tickets && eventMap.sections[s].tickets.length > 0) {
                // eslint-disable-next-line no-plusplus
                for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
                  if (
                    eventMap.sections[s].tickets[t].ticket.name ===
                    deselectedChartObject.labels.section
                  ) {
                    handleOnRemoveTicket(
                      eventMap.sections[s].tickets[t].ticket.id,
                      false,
                      eventMap.sections[s].section.id,
                      deselectedChartObject.label,
                    );
                  }
                }
              }
              break;
            }
          }
        }
      }
    }
  };

  const handleOnAddDiscount = async (): Promise<void> => {
    try {
      setStatus(States.loading);
      const { data } = await api.get<CheckDiscount>(
        `order/event/${formData[FormInputName.eventId]}/discount-coupon/${
          formData[FormInputName.code]
        }`,
      );
      setDiscount(data);
      let value = 0;
      if (data.discountCoupon) {
        value = Number(data.discountCoupon.discount);
      } else if (data.voucher) {
        value = Number(data.voucher.value);
      }
      if (value > 0) {
        setTotalWithFee(totalWithFee - value);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(States.default);
    }
  };

  const handleOnRemoveDiscount = (): void => {
    let value = 0;
    if (discount) {
      if (discount.discountCoupon) {
        value = Number(discount.discountCoupon.discount);
      } else if (discount.voucher) {
        value = Number(discount.voucher.value);
      }
    }

    if (value > 0) {
      setTotalWithFee(totalWithFee + value);
    }
    setDiscount(undefined);
    onChangeFormInput(FormInputName.code)('');
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    orderSelected,
    eventKey: eventKeySelected,
  }: OnShouldShowModalProps): Promise<void> => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    if (orderSelected) {
      setOrder(orderSelected);
    }
    if (eventKeySelected) {
      setEventKey(eventKeySelected);
    }
    onToggle();
  };

  const handleOnCheckCpf = async (cpf: string): Promise<void> => {
    formControllerUserData.resetForm();
    setDisableName(false);
    setDisableEmail(false);
    setDisablePhone(false);
    setDisableBirthDate(false);
    setDisableMotherName(false);
    try {
      setStatus(States.loading);
      const { data } = await api.get<CartEventTicketUserData>(`/order/cpf/${cpf}`);
      formControllerUserData.onChangeFormInput(UserDataFormInputName.document)(cpf);
      if (data.name) {
        if (data.name) {
          setDisableName(true);
          formControllerUserData.onChangeFormInput(UserDataFormInputName.name)(data.name);
        }
        if (data.email) {
          setDisableEmail(true);
          formControllerUserData.onChangeFormInput(UserDataFormInputName.email)(data.email);
        }
        if (data.phone) {
          setDisablePhone(true);
          formControllerUserData.onChangeFormInput(UserDataFormInputName.phone)(data.phone);
        }
        if (data.birthDate) {
          setDisableBirthDate(true);
          formControllerUserData.onChangeFormInput(UserDataFormInputName.birthDate)(
            data.birthDate ? dayjs(data.birthDate).format('DD/MM/YYYY') : '',
          );
        }
        if (data.motherName) {
          setDisableMotherName(true);
          formControllerUserData.onChangeFormInput(UserDataFormInputName.motherName)(
            data.motherName ? data.motherName : '',
          );
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(States.default);
    }
  };

  const handleOnSave = async (): Promise<void> => {
    try {
      if (isFormValid()) {
        let userData: CartEventTicketUserData | undefined;
        if (
          formData[FormInputName.paymentForm] === 'money' &&
          formControllerUserData.formData[UserDataFormInputName.document] &&
          formControllerUserData.formData[UserDataFormInputName.document].trim().length > 0
        ) {
          const isValid = formControllerUserData.isFormValid();
          if (formData[FormInputName.showUserData] === 'true' && !isValid) {
            return;
          }
          const birthDateSplit =
            formControllerUserData.formData[UserDataFormInputName.birthDate].split('/');
          const birthDateString = `${birthDateSplit[2]}-${birthDateSplit[1]}-${birthDateSplit[0]}T12:00:00.000Z`;
          userData = {
            name: formControllerUserData.formData[UserDataFormInputName.name],
            document: formControllerUserData.formData[UserDataFormInputName.document],
            email: formControllerUserData.formData[UserDataFormInputName.email].toLowerCase(),
            phone: formControllerUserData.formData[UserDataFormInputName.phone],
            birthDate: new Date(birthDateString),
            motherName: formControllerUserData.formData[UserDataFormInputName.motherName],
          };
        }
        try {
          setStatus(States.loading);
          const cartEvents: CartEvent[] = [];
          if (event && event.sections && event.sections.length > 0) {
            event.sections.forEach(eventSection => {
              if (eventSection.tickets && eventSection.tickets.length > 0) {
                const cartEvent: CartEvent = {
                  eventId: event.id,
                  tickets: [],
                };
                eventSection.tickets.forEach(ticket => {
                  if (ticket.amount && ticket.amount > 0) {
                    let ticketTotalValue = Number(ticket.unitValue);
                    if (ticket.fee && Number(ticket.fee) > 0) {
                      ticketTotalValue =
                        Number(ticket.unitValue) * Number(ticket.fee) - Number(ticket.unitValue);
                    }
                    const cartTicket: CartEventTicket = {
                      id: ticket.id,
                      unitValue: ticket.unitValue,
                      fee: ticket.fee,
                      paymentFee: 0,
                      totalValue: ticketTotalValue,
                      isHalfPrice: ticket.isHalfPrice,
                      lables: ticket.lables,
                      quantity: ticket.amount,
                      userDatas: [],
                    };
                    if (cartEvent.tickets && cartEvent.tickets.length > 0) {
                      cartEvent.tickets.push(cartTicket);
                    } else {
                      cartEvent.tickets = [];
                      cartEvent.tickets.push(cartTicket);
                    }
                  }
                });
                if (cartEvent.tickets && cartEvent.tickets.length > 0) {
                  cartEvents.push(cartEvent);
                }
              }
            });
          }

          let paymentMethod = PaymentMethod.SITE;
          if (formData[FormInputName.paymentForm] === 'site') {
            paymentMethod = PaymentMethod.SITE;
          } else if (formData[FormInputName.paymentForm] === 'pos') {
            paymentMethod = PaymentMethod.POS;
          } else if (formData[FormInputName.paymentForm] === 'money') {
            paymentMethod = PaymentMethod.MONEY;
          }
          const cart: Cart = {
            events: cartEvents,
            amountItens: amount,
            totalValue,
            totalValueWithFee: totalWithFee,
            totalValueWithPaymentFee: totalWithFee,
            discountCoupon:
              discount && discount.discountCoupon ? discount.discountCoupon : undefined,
            voucher: discount && discount.voucher ? discount.voucher : undefined,
            payments: [],
            paymentMethod,
            userData,
          };

          const { data } = await api.post<CreateOrderAdmResponse>('/order', cart);
          if (paymentMethod === PaymentMethod.SITE) {
            if (data.url) {
              handleOnShouldShowModal({
                value: ShouldShowModal.url,
                newTitleModal: 'Link para pagamento',
              });
            }
          } else if (paymentMethod === PaymentMethod.POS) {
            if (data.qrCode) {
              handleOnShouldShowModal({
                value: ShouldShowModal.qrCode,
                newTitleModal: 'QrCode de pagamento',
              });
            }
          } else if (paymentMethod === PaymentMethod.MONEY) {
            if (data.tickets && data.tickets.length > 0) {
              handleOnShouldShowModal({
                value: ShouldShowModal.ticket,
                newTitleModal: 'Link para pagamento',
              });
            }
          }
          setOrderResponse(data);
          resetForm();
          formControllerUserData.resetForm();
          setEvent(undefined);
          setAmount(0);
          setTotalValue(0);
          setFee(0);
          setDiscount(undefined);
          setTotalWithFee(0);
          setOrder(undefined);
          handleOnGetOrders(currentPage);
          setEventMap(undefined);
          setSelectedChartObject(undefined);
          setDeselectedChartObject(undefined);
          setDisableName(false);
          setDisableEmail(false);
          setDisablePhone(false);
          setDisableBirthDate(false);
          setDisableMotherName(false);
          setSelectedObjects([]);
        } catch (error) {
          const err = error as AxiosError;
          toast.error(err.message);
        } finally {
          setStatus(States.default);
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(States.default);
    }
  };

  const handleOnCopyUrl = (url: string): void => {
    navigator.clipboard.writeText(url);
    toast.info('Link copiado com sucesso!');
  };

  const handleOnPaginationChange = async (page: number): Promise<void> => {
    handleOnGetOrders({
      ...currentPage,
      page,
    });
  };

  const connectToWebSocket = (orderId: string): void => {
    const socket = new WebSocket(
      `wss://api.bancadoingresso.com.br/api/websocket/v1/printer/status/${orderId}`,
    );
    socket.onopen = (): void => {
      // eslint-disable-next-line no-console
      console.log('Connected to the web socket');
    };
    socket.onmessage = (m: MessageEvent<string>) => {
      if (m.data === 'PRINTED') {
        setStatus(States.default);
        onToggle();
        setShouldShowModal(ShouldShowModal.detail);
        toast.success('Pedido impresso com sucesso!');
      }
    };
    socket.onclose = () => {
      connectToWebSocket(orderId);
    };
    socket.onerror = () => {
      connectToWebSocket(orderId);
    };
  };

  const handleOnSendToServerPrinter = async (orderId: string): Promise<void> => {
    try {
      setStatus(States.loading);
      await api.post<void>(`/order/${orderId}/print-server`);
      connectToWebSocket(orderId);
    } catch (error) {
      setStatus(States.default);
      const err = error as AxiosError;
      toast.error(err.message);
    } /* finally {
      
    } */
  };

  useEffect(() => {
    if (selectedChartObject !== undefined) {
      handleOnSelectChartObjectSelected();
    }
  }, [selectedChartObject]);

  useEffect(() => {
    if (deselectedChartObject !== undefined) {
      handleOnChartObjectRemoveTicket();
    }
  }, [deselectedChartObject]);

  useEffect(() => {
    handleOnGetOrders(currentPage);
    handleOnGetEvents();
  }, []);

  return (
    <CreateSaleContainer
      state={state}
      title={title}
      visible={visible}
      shouldShowModal={shouldShowModal}
      onToggle={onToggle}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      events={events}
      onSelectEvent={handleOnGetEvent}
      event={event}
      eventMap={eventMap}
      eventKey={eventKey}
      selectedObjects={selectedObjects}
      onObjectSelected={handleOnChartObjectSelected}
      onObjectDeselected={handleOnChartObjectDeselected}
      onAddTicket={handleOnAddTicket}
      onRemoveTicket={handleOnRemoveTicket}
      onAddDiscount={handleOnAddDiscount}
      onRemoveDiscount={handleOnRemoveDiscount}
      discount={discount}
      formControllerUserData={formControllerUserData}
      amount={amount}
      totalValue={totalValue}
      fee={fee}
      totalWithFee={totalWithFee}
      onShouldShowModal={handleOnShouldShowModal}
      disableName={disableName}
      disableEmail={disableEmail}
      disablePhone={disablePhone}
      disableBirthDate={disableBirthDate}
      disableMotherName={disableMotherName}
      onCheckCpf={handleOnCheckCpf}
      save={handleOnSave}
      response={orderResponse}
      onCopyUrl={handleOnCopyUrl}
      currentPage={currentPage}
      onPaginationChange={handleOnPaginationChange}
      order={order}
      onSendToServerPrinter={handleOnSendToServerPrinter}
    />
  );
};
