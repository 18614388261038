import { States } from '@/helpers/common/states';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import ChartObject from '@/model/ChartObject';
import ChartSelectedTicketType from '@/model/ChartSelectedTicketType';
import Event from '@/model/Event';
import EventMap from '@/model/EventMap';
import EventMapSection from '@/model/EventMapSection';
import EventSiteGet from '@/model/EventSiteGet';
import { SelectedObject } from '@/model/SelectedObject';
import api from '@/services/api';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// import TicketSite from '@/model/TicketSite';
import TableBlock from '@/model/TableBlock';
import {
  LockedTables,
  OnShouldShowModalProps,
  SectionLockedTables,
  ShouldShowModal,
} from '../../types';
import { TableBlockUI } from './ui';

export const TableBlockScreen: React.FC = (): JSX.Element => {
  const [state, setStatus] = useState<States>(States.default);
  const [events, setEvents] = useState<Event[]>([]);
  const [event, setEvent] = useState<EventSiteGet>();
  const [eventMap, setEventMap] = useState<EventMap>();
  const [eventKey, setEventKey] = useState<string>();
  const [selectedObjects, setSelectedObjects] = useState<SelectedObject[]>([]);
  const [selectedChartObject, setSelectedChartObject] = useState<ChartObject>();
  const [deselectedChartObject, setDeselectedChartObject] = useState<ChartObject>();
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.map);
  //   const [tickets, setTickets] = useState<TicketSite[]>([]);
  const [tableBlock, setTableBock] = useState<TableBlock>({} as TableBlock);
  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const [lockedTables, setLockedTables] = useState<LockedTables[]>([]);

  const handleOnGetEvents = async (): Promise<void> => {
    try {
      setStatus(States.loading);
      const { data } = await api.get<Event[]>('/table/event');
      setEvents(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(States.default);
    }
  };

  const getEventKey = (ticketId: string): string => {
    let value = '';
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let s = 0; s < eventMap.sections.length; s++) {
        if (eventMap.sections[s].tickets && eventMap.sections[s].tickets.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
            if (eventMap.sections[s].tickets[t].ticket.id === ticketId) {
              value = eventMap.sections[s].tickets[t].eventKey;
              break;
            }
          }
        }
        if (value.length > 0) {
          break;
        }
      }
    }
    return value;
  };

  const handleOnGetEvent = async (idMap: string): Promise<void> => {
    try {
      setStatus(States.loading);
      const { data } = await api.get<EventSiteGet>(`/table/event/${idMap}`);
      try {
        const response = await api.get<EventMap>(`/table/map/${idMap}`);
        setEvent(data);
        setEventMap(response.data);
        getEventKey(response.data.id);
        const newTableBock: TableBlock = {
          eventID: idMap,
          eventKey: '',
          ticketID: '',
          labels: [],
        };
        setTableBock(newTableBock);
        if (lockedTables.length > 0) {
          setLockedTables([]);
        }
        const getLockedTables: LockedTables[] = [];
        if (response.data.lockedTables && response.data.lockedTables.length > 0) {
          const sections = response.data.sections as EventMapSection[];
          let idEventKey = '';
          sections.forEach(dados => {
            const sectionLockedTable: SectionLockedTables = {
              sectionName: dados.name,
              lockedtables: [],
            };
            if (dados.tickets && dados.tickets.length > 0) {
              dados.tickets.forEach(ticket => {
                if (ticket.eventKey !== '') {
                  idEventKey = getEventKey(ticket.ticket.id);
                }
              });
              sectionLockedTable.lockedtables = getLockedTables;
            }
          });
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < response.data.lockedTables.length; i++) {
            getLockedTables.push({
              eventID: idMap,
              label: response.data.lockedTables[i],
              eventKey: idEventKey,
            });
          }
        }
        setLockedTables(getLockedTables);
      } catch {
        toast.error('Nao foi possivel obter o mapa');
      }
    } catch (error) {
      const err = error as AxiosError;
      console.error(err);
      toast.error(err.message);
    } finally {
      setStatus(States.default);
    }
  };

  const { formData, formErrors, onChangeFormInput } = useForm({
    initialData: {
      eventId: '',
      sectionId: '',
      code: '',
      showUserData: 'false',
    },
  });

  const handleOnChartObjectSelected = (
    object: ChartObject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _selectedTicketType: ChartSelectedTicketType,
  ): void => {
    setSelectedChartObject(object);
  };
  const handleOnChartObjectDeselected = (
    object: ChartObject,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedTicketType: ChartSelectedTicketType,
  ): void => {
    setDeselectedChartObject(object);
  };

  const isTableBlockValid = (tables: TableBlock): boolean =>
    !!tables.eventKey && !!tables.eventID && !!tables.ticketID;

  const handleOnSubmitTableBlock = async (): Promise<void> => {
    try {
      setStatus(States.loading);
      if (isTableBlockValid(tableBlock) && selectedObjects.length > 0) {
        const labels: string[] = [];
        selectedObjects.forEach(data => {
          labels.push(data.label);
        });
        const payload = {
          eventID: tableBlock.eventID,
          eventKey: tableBlock.eventKey,
          ticketID: tableBlock.ticketID,
          labels,
        };
        const response = await api.post<TableBlock[]>('/table/block', payload);

        if (response.status === 200) {
          toast.success('Mesa bloqueada com sucesso!');
          handleOnGetEvent(tableBlock.eventID);
          setSelectedObjects([]);
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.status === '429') {
        toast.error('Limite excedido!');
      } else {
        toast.error(err.message);
      }
    } finally {
      setStatus(States.default);
    }
  };

  const handleOnUnlockTable = async (
    idMap: string,
    labelUnlock: string,
    eventKeyUnlock: string,
  ): Promise<void> => {
    try {
      if (!eventKeyUnlock) {
        return;
      }
      setStatus(States.loading);
      const payload = {
        eventID: idMap,
        label: labelUnlock,
        eventKey: eventKeyUnlock,
      };
      const response = await api.post<TableBlock[]>('/table/unlock', payload);
      if (response.status === 200) {
        toast.success('Mesa desbloqueada com sucesso!');
        handleOnGetEvent(idMap);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.status === '429') {
        toast.error('Limite excedido!');
      } else {
        toast.error(err.message);
      }
    } finally {
      setStatus(States.default);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    eventKey: eventKeySelected,
    ticketId,
  }: OnShouldShowModalProps): Promise<void> => {
    if (eventKeySelected) {
      const newTableBlock: TableBlock = {
        ...tableBlock,
        eventKey: eventKeySelected,
        ticketID: ticketId,
      };
      setTableBock(newTableBlock);
    }
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    if (eventKeySelected) {
      setEventKey(eventKeySelected);
    }
    onToggle();
  };

  const handleClearSelectedObjects = (): void => {
    setSelectedObjects([]);
  };

  const handleOnSelectChartObjectSelected = (): void => {
    if (selectedChartObject && eventMap) {
      const newSelectedObject: SelectedObject = {
        label: selectedChartObject.label,
        ticketType: selectedChartObject.selectedTicketType,
      };
      const newSelectedObjects: SelectedObject[] = [];
      let existsSeletedObject = false;
      selectedObjects.forEach(data => {
        if (
          data.label === newSelectedObject.label &&
          data.ticketType === newSelectedObject.ticketType
        ) {
          existsSeletedObject = true;
        }
        newSelectedObjects.push(data);
      });
      if (!existsSeletedObject) {
        newSelectedObjects.push(newSelectedObject);
        setSelectedObjects(newSelectedObjects);
      }
    }
  };

  const handleOnChartObjectRemoveTicket = (): void => {
    if (deselectedChartObject && eventMap) {
      const newSelectedObject: SelectedObject = {
        label: deselectedChartObject.label,
        ticketType: deselectedChartObject.selectedTicketType,
      };
      const newSelectedObjects: SelectedObject[] = [];
      //   let existsSeletedObject = false;
      selectedObjects.forEach(data => {
        if (
          data.label === newSelectedObject.label &&
          data.ticketType === newSelectedObject.ticketType
        ) {
          //   existsSeletedObject = true;
        } else {
          newSelectedObjects.push(data);
        }
      });
    }
  };

  useEffect(() => {
    if (selectedChartObject !== undefined) {
      handleOnSelectChartObjectSelected();
    }
  }, [selectedChartObject]);

  useEffect(() => {
    if (deselectedChartObject !== undefined) {
      handleOnChartObjectRemoveTicket();
    }
  }, [deselectedChartObject]);

  useEffect(() => {
    handleOnGetEvents();
  }, []);

  return (
    <TableBlockUI
      state={state}
      title={title}
      events={events}
      event={event}
      eventMap={eventMap}
      eventKey={eventKey}
      lockedTables={lockedTables}
      selectedObjects={selectedObjects}
      visible={visible}
      shouldShowModal={shouldShowModal}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      onObjectSelected={handleOnChartObjectSelected}
      onObjectDeselected={handleOnChartObjectDeselected}
      onToggle={onToggle}
      clearSelectedObjects={handleClearSelectedObjects}
      onSubmitTableBlock={handleOnSubmitTableBlock}
      onSelectEvent={handleOnGetEvent}
      onUnlockTable={handleOnUnlockTable}
      onShouldShowModal={handleOnShouldShowModal}
    />
  );
};
