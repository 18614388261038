import React from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { InputText, SelectCustom } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import { FormInputFilter } from '@/features/statement/types';
import Event from '@/model/Event';

interface FilterContentProps {
  formData: FormData;
  formErrors: FormErrors;
  onChangeFormInput: OnChangeFormInput;
  events: Event[];
}

export const FilterContent: React.FC<FilterContentProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
  events,
}) => {
  const options =
    events && events.length > 0
      ? events.map(data => ({
          value: data.id,
          label: data.name,
        }))
      : [];
  return (
    <Form
      noValidate={true}
      onSubmit={(e): void => {
        e.preventDefault();
      }}
    >
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="startDate"
              label="Data inicial"
              placeholder="01/01/2000"
              maxLength={10}
              value={formData[FormInputFilter.startDate]}
              onChange={e => onChangeFormInput(FormInputFilter.startDate)(e.target.value)}
              error={formErrors.startDate && formErrors.startDate[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="endDate"
              label="Data final"
              placeholder="01/01/2000"
              maxLength={10}
              value={formData[FormInputFilter.endDate]}
              onChange={e => onChangeFormInput(FormInputFilter.endDate)(e.target.value)}
              error={formErrors.endDate && formErrors.endDate[0]}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="eventId"
              label="Evento"
              placeholder="Digite ou selecione o evento"
              onChange={e => {
                onChangeFormInput(FormInputFilter.eventId)(e?.value as string);
              }}
              error={formErrors.eventId && formErrors.eventId[0]}
              value={formData[FormInputFilter.eventId]}
              options={options}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <SelectCustom
              name="type"
              label="Tipo"
              placeholder="Digite ou selecione o tipo"
              onChange={e => {
                onChangeFormInput(FormInputFilter.type)(e?.value as string);
              }}
              error={formErrors.type && formErrors.type[0]}
              value={formData[FormInputFilter.type]}
              options={[
                { value: 0, label: 'Resgate' },
                { value: 1, label: 'Lançamento' },
              ]}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
