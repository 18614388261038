import { States } from '@/helpers/common/states';
import validators from '@/helpers/validators';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import Event from '@/model/Event';
import EventMap from '@/model/EventMap';
import EventMapSection from '@/model/EventMapSection';
import EventMapTicket from '@/model/EventMapTicket';
import EventTicket from '@/model/EventTicket';
import Section from '@/model/Section';
import Ticket from '@/model/Ticket';
import TicketSite from '@/model/TicketSite';
import api from '@/services/api';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FormInputName,
  FormInputRegister,
  FormInputTicket,
  MapControler,
  OnShouldShowModalProps,
  ShouldShowModal,
} from '../types';
import { EventMapContainer } from './ui';

export const EventMapScreen: React.FC = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState(States.default);
  const [event, setEvent] = useState<Event>({} as Event);
  const [eventMap, setEventMap] = useState<EventMap>({} as EventMap);
  const [selectedSectionId, setSelectedSectionId] = useState<string>();
  const [mapTickets, setMapTickets] = useState<EventMapTicket[]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [sectionTickets, setSectionTickets] = useState<Ticket[]>([]);
  const [eventKey, setEventKey] = useState<string>();
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.map);

  const history = useHistory();

  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const getEvent = async (eventId: string): Promise<void> => {
    const { data } = await api.get<Event>(`/event/${eventId}/simple`);
    setEvent(data);
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      secretWorkspaceKey: '',
      publicWorkspaceKey: '',
    },
    validators: {
      secretWorkspaceKey: [validators.required],
      publicWorkspaceKey: [validators.required],
    },
  });

  const mapControler: MapControler = {
    formData,
    formErrors,
    onChangeFormInput,
    isFormValid,
    resetForm,
  };

  const {
    formData: formDataConfig,
    formErrors: formErrorsConfig,
    onChangeFormInput: onChangeFormInputConfig,
    isFormValid: isFormValidConfig,
    resetForm: resetFormConfig,
  } = useForm({
    initialData: {
      id: '',
      section: '',
      name: '',
    },
    validators: {
      section: [validators.required],
      name: [validators.required],
    },
  });

  const {
    formData: formDataTicket,
    formErrors: formErrorsTicket,
    onChangeFormInput: onChangeFormInputTicket,
    isFormValid: isFormValidTicket,
    resetForm: resetFormTicket,
  } = useForm({
    initialData: {
      id: '',
      ticket: '',
      eventKey: '',
      mapName: '',
      hasTable: 'false',
      seats: '',
      bookableAsAWhole: 'false',
    },
    validators: {
      ticket: [validators.required],
      eventKey: [validators.required],
    },
  });

  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
    eventMap: eventMapSelected,
    eventKey: eventKeySelected,
  }: OnShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    if (eventMapSelected) {
      setEventMap(eventMapSelected);
    }
    if (eventKeySelected) {
      setEventKey(eventKeySelected);
    }

    let willToogle = true;
    if (value === ShouldShowModal.map) {
      willToogle = false;
    } else if (value === ShouldShowModal.ticket && shouldShowModal === ShouldShowModal.ticket) {
      willToogle = false;
    }

    if (willToogle) {
      onToggle();
    }
  };

  const configureSectorControler: MapControler = {
    formData: formDataConfig,
    formErrors: formErrorsConfig,
    onChangeFormInput: onChangeFormInputConfig,
    isFormValid: isFormValidConfig,
    resetForm: resetFormConfig,
  };

  const getMap = async (eventId: string): Promise<void> => {
    const { data } = await api.get<EventMap>(`event/map/${eventId}`);
    if (data && data.id) {
      mapControler.onChangeFormInput(FormInputName.secretWorkspaceKey)(data.secretWorkspaceKey);
      mapControler.onChangeFormInput(FormInputName.publicWorkspaceKey)(data.publicWorkspaceKey);
      setEventMap(data);
      if (selectedSectionId && selectedSectionId.trim().length > 0) {
        if (data.sections && data.sections.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < data.sections.length; i++) {
            if (selectedSectionId === data.sections[i].id) {
              const newTickets: EventMapTicket[] = [];
              if (data.sections[i].tickets && data.sections[i].tickets.length > 0) {
                data.sections[i].tickets.forEach(item => {
                  newTickets.push(item);
                });
                setMapTickets(newTickets);
              } else {
                setMapTickets([]);
              }
              break;
            }
          }
        }
      }
    }
  };

  const getTickets = async (eventId: string): Promise<void> => {
    const { data } = await api.get<EventTicket>(`/event/ticket/${eventId}`);
    if (data && data.tickets && data.tickets.length > 0) {
      const newSections: Section[] = [];
      data.tickets.forEach(ticket => {
        let found = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < newSections.length; i++) {
          if (ticket.eventSection.id === newSections[i].id) {
            found = true;
            break;
          }
        }
        if (!found) {
          newSections.push(ticket.eventSection);
        }
      });
      setTickets(data.tickets);
      setSections(newSections);
    }
  };

  const getEventAndTickets = async (eventId: string): Promise<void> => {
    try {
      setState(States.loading);
      await getEvent(eventId);
      await getMap(eventId);
      await getTickets(eventId);
    } finally {
      setState(States.default);
    }
  };

  const ticketControler: MapControler = {
    formData: formDataTicket,
    formErrors: formErrorsTicket,
    onChangeFormInput: onChangeFormInputTicket,
    isFormValid: isFormValidTicket,
    resetForm: resetFormTicket,
  };

  const handleOnCancel = (): void => {
    configureSectorControler.resetForm();
    ticketControler.resetForm();
    setShouldShowModal(ShouldShowModal.map);
    onToggle();
    setMapTickets([]);
  };

  const openSection = (sectionId: string): void => {
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < eventMap.sections.length; i++) {
        if (eventMap.sections[i].id === sectionId) {
          configureSectorControler.onChangeFormInput(FormInputRegister.id)(eventMap.sections[i].id);
          configureSectorControler.onChangeFormInput(FormInputRegister.section)(
            eventMap.sections[i].section.id,
          );
          configureSectorControler.onChangeFormInput(FormInputRegister.name)(
            eventMap.sections[i].name,
          );
          handleOnShouldShowModal({
            value: ShouldShowModal.confg,
            newTitleModal: 'Configurar Categoria',
          });
          if (eventMap.sections[i].tickets && eventMap.sections[i].tickets.length > 0) {
            setMapTickets(eventMap.sections[i].tickets);
          } else {
            setMapTickets([]);
          }
          break;
        }
      }
    }
  };

  const back = (): void => {
    history.push('/dashboard/event');
  };

  const save = async (): Promise<void> => {
    if (mapControler.isFormValid()) {
      try {
        setState(States.loading);
        const newEventMap: EventMap = {
          id: eventMap.id,
          secretWorkspaceKey: mapControler.formData[FormInputName.secretWorkspaceKey],
          publicWorkspaceKey: mapControler.formData[FormInputName.publicWorkspaceKey],
        };
        await api.post(`event/map/${id}`, newEventMap);
        await getMap(id);
        toast.success('Mapa Inserido/Alterado com sucesso!');
      } finally {
        setState(States.default);
      }
    }
  };

  const saveSection = async (): Promise<void> => {
    if (configureSectorControler.isFormValid()) {
      try {
        setState(States.loading);
        const eventMapSection = {
          section: { id: configureSectorControler.formData[FormInputRegister.section] } as Section,
          name: configureSectorControler.formData[FormInputRegister.name],
        } as EventMapSection;
        if (
          configureSectorControler.formData[FormInputRegister.id] &&
          configureSectorControler.formData[FormInputRegister.id].trim().length > 0
        ) {
          eventMapSection.id = configureSectorControler.formData[FormInputRegister.id];
        }

        await api.post(`event/map/${id}/section`, eventMapSection);
        await getMap(id);
        resetFormConfig();
        toast.success('Setor Inserido/Alterado com sucesso!');
      } finally {
        setState(States.default);
      }
    }
  };

  const removeSection = async (sectionId: string): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`event/map/${id}/section/${sectionId}`);
      await getMap(id);
      toast.success('Setor removido com sucesso!');
    } finally {
      setState(States.default);
    }
  };

  const openTicket = (sectionId: string, ticketId: string | undefined): void => {
    const list: Ticket[] = [];
    if (!sectionId) {
      toast.warn('Informe o setor');
    } else {
      setSelectedSectionId(sectionId);
      if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < eventMap.sections.length; i++) {
          if (eventMap.sections[i].id === sectionId) {
            const eventSectionId = eventMap.sections[i].section.id;
            tickets.forEach(data => {
              if (data.eventSection.id === eventSectionId) {
                list.push(data);
              }
            });
            setSectionTickets(list);
            setMapTickets(eventMap.sections[i].tickets);
            if (ticketId) {
              if (eventMap.sections[i].tickets && eventMap.sections[i].tickets.length > 0) {
                // eslint-disable-next-line no-plusplus
                for (let t = 0; t < eventMap.sections[i].tickets.length; t++) {
                  if (eventMap.sections[i].tickets[t].id === ticketId) {
                    ticketControler.onChangeFormInput(FormInputTicket.id)(
                      eventMap.sections[i].tickets[t].id,
                    );
                    ticketControler.onChangeFormInput(FormInputTicket.ticket)(
                      eventMap.sections[i].tickets[t].ticket.id,
                    );
                    ticketControler.onChangeFormInput(FormInputTicket.eventKey)(
                      eventMap.sections[i].tickets[t].eventKey,
                    );
                    ticketControler.onChangeFormInput(FormInputTicket.mapName)(
                      eventMap.sections[i].tickets[t].mapName || '',
                    );
                    ticketControler.onChangeFormInput(FormInputTicket.hasTable)(
                      eventMap.sections[i].tickets[t].hasTable ? 'true' : 'false',
                    );
                    ticketControler.onChangeFormInput(FormInputTicket.seats)(
                      `${eventMap.sections[i].tickets[t].seats}`,
                    );
                    ticketControler.onChangeFormInput(FormInputTicket.bookableAsAWhole)(
                      eventMap.sections[i].tickets[t].bookableAsAWhole ? 'true' : 'false',
                    );
                    break;
                  }
                }
              }
            }
            break;
          }
        }
      }
      handleOnShouldShowModal({
        value: ShouldShowModal.ticket,
        newTitleModal: 'Cadastro de Ingresso',
      });
    }
  };

  const saveTicket = async (sectionId: string): Promise<void> => {
    if (ticketControler.isFormValid()) {
      try {
        setState(States.loading);
        const eventMapTicket = {
          ticket: { id: ticketControler.formData[FormInputTicket.ticket] } as TicketSite,
          eventKey: ticketControler.formData[FormInputTicket.eventKey],
          mapName: ticketControler.formData[FormInputTicket.mapName]
            ? ticketControler.formData[FormInputTicket.mapName]
            : undefined,
          hasTable: ticketControler.formData[FormInputTicket.hasTable] === 'true',
          seats: Number(ticketControler.formData[FormInputTicket.seats]),
          bookableAsAWhole: ticketControler.formData[FormInputTicket.bookableAsAWhole] === 'true',
        } as EventMapTicket;
        if (
          ticketControler.formData[FormInputTicket.id] &&
          ticketControler.formData[FormInputTicket.id].trim().length > 0
        ) {
          eventMapTicket.id = ticketControler.formData[FormInputTicket.id];
        }

        await api.post(`event/map/${id}/section/${sectionId}/ticket`, eventMapTicket);
        await getMap(id);
        resetFormTicket();
        toast.success('Ingresso Inserido/Alterado com sucesso!');
      } finally {
        setState(States.default);
      }
    }
  };

  const removeTicket = async (sectionId: string, ticketId: string): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`event/map/${id}/section/${sectionId}/ticket/${ticketId}`);
      await getMap(id);
      toast.success('Ingresso removido com sucesso!');
    } finally {
      setState(States.default);
    }
  };

  useEffect(() => {
    getEventAndTickets(id);
  }, []);

  return (
    <EventMapContainer
      state={state}
      event={event}
      eventMap={eventMap}
      mapControler={mapControler}
      title={title}
      visible={visible}
      onToggle={onToggle}
      onShouldShowModal={handleOnShouldShowModal}
      shouldShowModal={shouldShowModal}
      back={back}
      save={save}
      configureSectorControler={configureSectorControler}
      sections={sections}
      onCancel={handleOnCancel}
      openSection={openSection}
      saveSection={saveSection}
      sectionTickets={sectionTickets}
      removeSection={removeSection}
      sectionId={selectedSectionId}
      ticketControler={ticketControler}
      openTicket={openTicket}
      mapTickets={mapTickets}
      saveTicket={saveTicket}
      removeTicket={removeTicket}
      eventKey={eventKey}
    />
  );
};
