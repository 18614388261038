import React from 'react';

const IconCloseCircle: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.40381 14.5962C7.94225 17.1346 12.0578 17.1346 14.5962 14.5962C17.1346 12.0578 17.1346 7.94225 14.5962 5.40381C12.0578 2.8654 7.94221 2.8654 5.40381 5.40381C2.86536 7.94225 2.8654 12.0578 5.40381 14.5962Z"
      stroke="#2A2A2A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1213 7.87868L7.8787 12.1213"
      stroke="#2A2A2A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.87866 7.87868L12.1213 12.1213"
      stroke="#2A2A2A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCloseCircle;
