import { Button, ButtonGroup, InputText, Loading } from '@/components';
import React from 'react';
import { Col, Container, Form, FormGroup, Row } from 'reactstrap';
import { EventState } from '@/features/registerEvent/hook/useEvent';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { FormInput, States } from '../types';

interface StateProps {
  state: States;
  eventState: EventState;
  formData: FormData;
  formErrors: FormErrors;
}

interface DispatchProps {
  onChangeEvent: (data: Partial<EventState>) => void;
  onChangeFormInput: OnChangeFormInput;
  onSaveEventFee: () => void;
}

type Props = StateProps & DispatchProps;

export const FeeEventContainer: React.FC<Props> = ({
  state,
  eventState,
  onChangeEvent,
  formData,
  onChangeFormInput,
  formErrors,
  onSaveEventFee,
}) => (
  <Form
    noValidate={true}
    onSubmit={(e): void => {
      e.preventDefault();
      e.stopPropagation();
      onSaveEventFee();
    }}
  >
    <Loading isVisible={state === States.loading} />
    <Container className="mainContainer" fluid={true}>
      <div className="container-event">
        <h5 className="mb-2 border-bottom-title mb-5">Taxas vendas sobre ingresso</h5>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <ButtonGroup
                label="Taxas administrativas"
                name="calcAdmFee"
                value={formData[FormInput.calcAdmFee]}
                onChange={e => onChangeFormInput(FormInput.calcAdmFee)(e.target.value)}
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                error={formErrors.calcAdmFee && formErrors.calcAdmFee[0]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="pdv"
                label="Comissão Banca"
                placeholder="1.00%"
                maxLength={5}
                value={formData[FormInput.pdv]}
                onChange={e => onChangeFormInput(FormInput.pdv)(e.target.value)}
                error={formErrors.pdv && formErrors.pdv[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="mb-2">
              <ButtonGroup
                label="Comissão sobre venda"
                name="generalCommission"
                value={formData[FormInput.generalCommission]}
                onChange={e => onChangeFormInput(FormInput.generalCommission)(e.target.value)}
                options={[
                  { value: true, label: 'Geral' },
                  { value: false, label: 'PDV' },
                ]}
                error={formErrors.generalCommission && formErrors.generalCommission[0]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="credit"
                label="Crédito à vista"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.credit]}
                onChange={e => onChangeFormInput(FormInput.credit)(e.target.value)}
                error={formErrors.credit && formErrors.credit[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="installment"
                label="Crédito parcelado"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.installment]}
                onChange={e => onChangeFormInput(FormInput.installment)(e.target.value)}
                error={formErrors.installment && formErrors.installment[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="debit"
                label="Débito"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.debit]}
                onChange={e => onChangeFormInput(FormInput.debit)(e.target.value)}
                error={formErrors.debit && formErrors.debit[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="pix"
                label="Pix"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.pix]}
                onChange={e => onChangeFormInput(FormInput.pix)(e.target.value)}
                error={formErrors.pix && formErrors.pix[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="bankslip"
                label="Boleto"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.bankslip]}
                onChange={e => onChangeFormInput(FormInput.bankslip)(e.target.value)}
                error={formErrors.bankslip && formErrors.bankslip[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5 className="mb-2 border-bottom-title mb-5">Despesas vendas sobre ingresso</h5>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="food"
                label="Alimentação"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.food]}
                onChange={e => onChangeFormInput(FormInput.food)(e.target.value)}
                error={formErrors.food && formErrors.food[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="technical"
                label="Técnicos"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.technical]}
                onChange={e => onChangeFormInput(FormInput.technical)(e.target.value)}
                error={formErrors.technical && formErrors.technical[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="trip"
                label="Viagem"
                placeholder="1.000,00"
                maxLength={10}
                value={formData[FormInput.trip]}
                onChange={e => onChangeFormInput(FormInput.trip)(e.target.value)}
                error={formErrors.trip && formErrors.trip[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="rentPos"
                label="Aluguel POS"
                placeholder="80,00"
                maxLength={10}
                value={formData[FormInput.rentPos]}
                onChange={e => onChangeFormInput(FormInput.rentPos)(e.target.value)}
                error={formErrors.rentPos && formErrors.rentPos[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>

        <h5 className="mb-2 border-bottom-title mb-5">Taxas vendas sobre bar</h5>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <ButtonGroup
                label="Taxas administrativas"
                name="calcAdmFeeProduct"
                value={formData[FormInput.calcAdmFeeProduct]}
                onChange={e => onChangeFormInput(FormInput.calcAdmFeeProduct)(e.target.value)}
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                error={formErrors.calcAdmFeeProduct && formErrors.calcAdmFeeProduct[0]}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="pdvProduct"
                label="Comissão Banca"
                placeholder="1.00%"
                maxLength={5}
                value={formData[FormInput.pdvProduct]}
                onChange={e => onChangeFormInput(FormInput.pdvProduct)(e.target.value)}
                error={formErrors.pdvProduct && formErrors.pdvProduct[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="mb-2">
              <ButtonGroup
                label="Comissão sobre venda"
                name="generalCommissionProduct"
                value={formData[FormInput.generalCommissionProduct]}
                onChange={e =>
                  onChangeFormInput(FormInput.generalCommissionProduct)(e.target.value)
                }
                options={[
                  { value: true, label: 'Geral' },
                  { value: false, label: 'PDV' },
                ]}
                error={
                  formErrors.generalCommissionProduct && formErrors.generalCommissionProduct[0]
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="creditProduct"
                label="Crédito à vista"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.creditProduct]}
                onChange={e => onChangeFormInput(FormInput.creditProduct)(e.target.value)}
                error={formErrors.creditProduct && formErrors.creditProduct[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="installmentProduct"
                label="Crédito parcelado"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.installmentProduct]}
                onChange={e => onChangeFormInput(FormInput.installmentProduct)(e.target.value)}
                error={formErrors.installmentProduct && formErrors.installmentProduct[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="debitProduct"
                label="Débito"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.debitProduct]}
                onChange={e => onChangeFormInput(FormInput.debitProduct)(e.target.value)}
                error={formErrors.debitProduct && formErrors.debitProduct[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="pixProduct"
                label="Pix"
                placeholder="2.50%"
                maxLength={5}
                value={formData[FormInput.pixProduct]}
                onChange={e => onChangeFormInput(FormInput.pixProduct)(e.target.value)}
                error={formErrors.pixProduct && formErrors.pixProduct[0]}
                addon={'%'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="bankSlipProduct"
                label="Boleto"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.bankSlipProduct]}
                onChange={e => onChangeFormInput(FormInput.bankSlipProduct)(e.target.value)}
                error={formErrors.bankSlipProduct && formErrors.bankSlipProduct[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <h5 className="mb-2 border-bottom-title mb-5">Despesas vendas sobre bar</h5>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="foodProduct"
                label="Alimentação"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.foodProduct]}
                onChange={e => onChangeFormInput(FormInput.foodProduct)(e.target.value)}
                error={formErrors.foodProduct && formErrors.foodProduct[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="technicalProduct"
                label="Técnicos"
                placeholder="2,50"
                maxLength={10}
                value={formData[FormInput.technicalProduct]}
                onChange={e => onChangeFormInput(FormInput.technicalProduct)(e.target.value)}
                error={formErrors.technicalProduct && formErrors.technicalProduct[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="tripProduct"
                label="Viagem"
                placeholder="1.000,00"
                maxLength={10}
                value={formData[FormInput.tripProduct]}
                onChange={e => onChangeFormInput(FormInput.tripProduct)(e.target.value)}
                error={formErrors.tripProduct && formErrors.tripProduct[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup className="mb-2">
              <InputText
                name="rentPosProduct"
                label="Aluguel POS"
                placeholder="80,00"
                maxLength={10}
                value={formData[FormInput.rentPosProduct]}
                onChange={e => onChangeFormInput(FormInput.rentPosProduct)(e.target.value)}
                error={formErrors.rentPosProduct && formErrors.rentPosProduct[0]}
                addon={'R$'}
              />
            </FormGroup>
          </Col>
        </Row>

        <hr />
        <div className="footer-register-event">
          <Button
            title="Voltar"
            theme="noneBorder"
            onClick={() => {
              onChangeEvent({ ...eventState, currentStep: eventState.currentStep - 1 });
            }}
          />

          <Button title="Avançar para Confirmação" onClick={onSaveEventFee} />
        </div>
      </div>
    </Container>
  </Form>
);
