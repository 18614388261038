// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  map = 'map',
}

// eslint-disable-next-line no-shadow
export enum FormInputName {
  eventId = 'eventId',
  sectionId = 'sectionId',
  code = 'code',
  showUserData = 'showUserData',
}

export interface LockedTables {
  eventID: string;
  label: string;
  eventKey: string;
}

export interface SectionLockedTables {
  sectionName: string;
  lockedtables: LockedTables[];
}

export interface OnShouldShowModalProps {
  value: ShouldShowModal;
  newTitleModal: string | React.ReactNode;
  eventKey?: string;
  ticketId: string;
}
