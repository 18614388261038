import React, { useEffect, useState } from 'react';
import { States } from '@/helpers/common/states';
import api, { AxiosError } from '@/services/api';
import { StatementFilter } from '@/model/StatementFilter';
import { StatementResponseData } from '@/model/StatementResponseData';
import { toast } from 'react-toastify';
import useForm from '@/hooks/useForm';
import StatementType from '@/model/StatementType';
import { useDialog } from '@/hooks/useDialog';
import Event from '@/model/Event';
import { updateMask } from '@/helpers/masks/generalDate';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import { EventCloseExpense } from '@/model/EventCloseExpense';
import { StatementSContainer } from './ui';
import { FormInputFilter, ShouldShowModal } from '../types';

export const StatementScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [filter, setFilter] = useState<StatementFilter>({});
  const [statements, setStatements] = useState<StatementResponseData[]>([]);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [events, setEvents] = useState<Event[]>([]);
  const [eventMoneyTransfer, setEventMoneyTransfer] = useState<EventMoneyTransfer>();
  const [eventCloseExpense, setEventCloseExpense] = useState<EventCloseExpense>();

  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const handleOnGetStatements = async (selectedFilter: StatementFilter): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<StatementResponseData[]>(
        '/event-money-transfer/page/statement',
        selectedFilter,
      );

      if (data) {
        setStatements(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeFormInput: onChangeFormInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm({
    initialData: {
      startDate: '',
      endDate: '',
      eventId: '',
      type: '',
    },
    formatters: {
      startDate: updateMask,
      endDate: updateMask,
    },
  });

  const handleOnGetEvents = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Event[]>('/event/find');

      if (data && data.length > 0) {
        setEvents(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnGetEventMoneyTransfer = async (selectedId: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventMoneyTransfer>(`/event-money-transfer/${selectedId}`);
      if (data) {
        setEventMoneyTransfer(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnGetEventCloseExpense = async (
    eventId: string,
    selectedId: string,
  ): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventCloseExpense>(
        `/event/close/${eventId}/expense/${selectedId}`,
      );
      if (data) {
        setEventCloseExpense(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = async (
    value: ShouldShowModal,
    newTitleModal: string | React.ReactNode,
    selectedId?: string,
    type?: StatementType,
    eventId?: string,
  ): Promise<void> => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    if (value === ShouldShowModal.filter && (!events || events.length === 0)) {
      await handleOnGetEvents();
    } else if (
      value === ShouldShowModal.detailRequest &&
      type === StatementType.REQUEST &&
      selectedId
    ) {
      await handleOnGetEventMoneyTransfer(selectedId);
    } else if (
      value === ShouldShowModal.detailManual &&
      type === StatementType.MANUAL &&
      selectedId &&
      eventId
    ) {
      await handleOnGetEventCloseExpense(eventId, selectedId);
    }
    onToggle();
  };

  const handleOnClearFilter = async (): Promise<void> => {
    resetFormFilter();
    setFilter({});
    onToggle();
    await handleOnGetStatements({});
  };

  const handleOnCancelDetail = (): void => {
    setEventMoneyTransfer(undefined);
    setEventCloseExpense(undefined);
    onToggle();
  };

  const handleOnFilter = async (): Promise<void> => {
    try {
      if (isFormValidFilter()) {
        let type: StatementType | undefined;
        if (formDataFilter[FormInputFilter.type]) {
          type = Number(formDataFilter[FormInputFilter.type]);
        }
        const eventId = formDataFilter[FormInputFilter.eventId];
        const payload: StatementFilter = {
          startDate: formDataFilter[FormInputFilter.startDate],
          endDate: formDataFilter[FormInputFilter.endDate],
          eventId: eventId && eventId.trim().length > 0 ? eventId : undefined,
          type,
        };

        setFilter(payload);

        await handleOnGetStatements(payload);

        onToggle();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  useEffect(() => {
    handleOnGetStatements(filter);
  }, []);
  return (
    <StatementSContainer
      state={state}
      statements={statements}
      onShouldShowModal={handleOnShouldShowModal}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      onChangeFormInputFilter={onChangeFormInputFilter}
      events={events}
      onFilter={handleOnFilter}
      onClearFilter={handleOnClearFilter}
      onCancelDetail={handleOnCancelDetail}
      eventMoneyTransfer={eventMoneyTransfer}
      eventCloseExpense={eventCloseExpense}
    />
  );
};
