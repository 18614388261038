import React, { useEffect, useState } from 'react';
import Page from '@/model/Page';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import EventMoneyTransferStatus from '@/model/EventMoneyTransferStatus';
import { FormInput, FormInputRefuse, ShouldShowModal, States } from '../types';
import { RedemptionContainer } from './ui';
import { TransferContent } from '../components/TransferContent';

export const RedemptionScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [page, setPage] = useState<Page<EventMoneyTransfer, EventMoneyTransfer>>({
    page: 1,
    pageSize: 10,
    entity: {
      transferStatus: EventMoneyTransferStatus.WAITING,
    } as EventMoneyTransfer,
    sort: 'transferRequestDate',
    order: 'ASC',
  });
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [eventMoneyTransfer, setEventMoneyTransfer] = useState<EventMoneyTransfer>();

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const handleOnPage = async (
    pageData: Page<EventMoneyTransfer, EventMoneyTransfer>,
  ): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<EventMoneyTransfer, EventMoneyTransfer>>(
        '/event-money-transfer/page/requests',
        pageData,
      );
      if (data) {
        setPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const {
    formData: formDataRefuse,
    formErrors: formErrorsRefuse,
    onChangeFormInput: onChangeFormInputRefuse,
    isFormValid: isFormValidRefuse,
    resetForm: resetFormRefuse,
  } = useForm({
    initialData: {
      id: '',
      refusedReason: '',
    },
    validators: {
      id: [validators.required],
      refusedReason: [validators.required],
    },
  });

  const handleOnGet = async (id: string, isRefuse: boolean): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventMoneyTransfer>(`/event-money-transfer/${id}`);
      if (data) {
        setEventMoneyTransfer(data);
        if (isRefuse) {
          onChangeFormInputRefuse(FormInputRefuse.id)(data.id);
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    id,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    id?: string;
  }): Promise<void> => {
    if (id && (value === ShouldShowModal.detail || value === ShouldShowModal.refuse)) {
      await handleOnGet(id, value === ShouldShowModal.refuse);
    }

    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      filterSearch: '',
      inputSearch: '',
    },
    validators: {
      inputSearch: [validators.required],
    },
  });

  const handleOnClearFilter = async (): Promise<void> => {
    resetForm();
    const newPage: Page<EventMoneyTransfer, EventMoneyTransfer> = {
      page: 1,
      pageSize: 10,
      sort: 'transferRequestDate',
      order: 'ASC',
    };
    await handleOnPage(newPage);
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValid()) {
      const newPage: Page<EventMoneyTransfer, EventMoneyTransfer> = {
        page: 1,
        pageSize: 10,
        entity: {
          transferStatus:
            formData[FormInput.inputSearch] === 'true'
              ? EventMoneyTransferStatus.TRANSFERRED
              : EventMoneyTransferStatus.WAITING,
        } as EventMoneyTransfer,
        sort: 'startDate',
        order: 'DESC',
        total: 1,
      };
      onToggle();

      await handleOnPage(newPage);
    }
  };

  const handleOnConfirmTransfer = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      await api.patch(`/event-money-transfer/${id}`);
      confirmDelete.hide();
      handleOnClearFilter();
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const handleOnTransfer = (id: string): void => {
    confirmDelete.show({
      title: '',
      children: <TransferContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => confirmDelete.hide(),
        },
        {
          title: 'Sim, quero transferir',
          onClick: (): Promise<void> => handleOnConfirmTransfer(id),
        },
      ],
    });
  };

  const handleOnRefuse = async (): Promise<void> => {
    if (isFormValidRefuse()) {
      try {
        setState(States.loading);
        const payload = {
          id: formDataRefuse[FormInputRefuse.id],
          refusedReason: formDataRefuse[FormInputRefuse.refusedReason],
        };
        await api.patch(`/event-money-transfer/${payload.id}/refuse`, payload);
        resetFormRefuse();
        onToggle();
        handleOnPage(page);
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status !== 401) {
          toast.error(err.message);
        }
      } finally {
        setState(States.default);
      }
    }
  };

  const handleOnPaginationChange = async (newPageNumber: number): Promise<void> => {
    const newPage: Page<EventMoneyTransfer, EventMoneyTransfer> = {
      ...page,
      page: newPageNumber,
    };
    await handleOnPage(newPage);
  };
  useEffect(() => {
    handleOnPage(page);
  }, []);
  return (
    <RedemptionContainer
      state={state}
      title={title}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={handleOnShouldShowModal}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      onClearFilter={handleOnClearFilter}
      onFilter={handleOnFilter}
      page={page}
      eventMoneyTransfer={eventMoneyTransfer}
      onTransfer={handleOnTransfer}
      formDataRefuse={formDataRefuse}
      formErrorsRefuse={formErrorsRefuse}
      onChangeFormInputRefuse={onChangeFormInputRefuse}
      onRefuse={handleOnRefuse}
      onPaginationChange={handleOnPaginationChange}
    />
  );
};
