import React from 'react';
import { Route } from '@/navigation/Route';
import { TableBlockScreen } from './screens/TableBlock';

export const TABLE_BLOCK_ROUTES = {
  itself: '/table-block',
};

export const TableBlockNavigation = (): JSX.Element => (
  <Route exact path={TABLE_BLOCK_ROUTES.itself} component={TableBlockScreen} isPrivateRoute />
);
