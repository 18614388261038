export type UrlParams = {
  id: string;
};

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

// eslint-disable-next-line no-shadow
export enum FormInput {
  calcAdmFee = 'calcAdmFee',
  pdv = 'pdv',
  generalCommission = 'generalCommission',
  credit = 'credit',
  installment = 'installment',
  debit = 'debit',
  pix = 'pix',
  bankslip = 'bankslip',
  food = 'food',
  technical = 'technical',
  trip = 'trip',
  rentPos = 'rentPos',
  calcAdmFeeProduct = 'calcAdmFeeProduct',
  pdvProduct = 'pdvProduct',
  generalCommissionProduct = 'generalCommissionProduct',
  creditProduct = 'creditProduct',
  installmentProduct = 'installmentProduct',
  debitProduct = 'debitProduct',
  pixProduct = 'pixProduct',
  bankSlipProduct = 'bankSlipProduct',
  foodProduct = 'foodProduct',
  technicalProduct = 'technicalProduct',
  tripProduct = 'tripProduct',
  rentPosProduct = 'rentPosProduct',
}
