import React, { useEffect } from 'react';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import { InputText, SelectCustom } from '@/components';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks/useForm';
import Contractor from '@/model/Contractor';
import { OptionProps } from '@/components/Select';
import { REACT_APP_USER } from '@/utils/config';
import UserType from '@/model/UserType';
import { FormInput } from '../../types';

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  contractors: Contractor[];
}

interface DispatchProps {
  onChangeFormInput: OnChangeFormInput;
}

type Props = StateProps & DispatchProps;

export const TransferContent: React.FC<Props> = ({
  formData,
  formErrors,
  contractors,
  onChangeFormInput,
}) => {
  const user = JSON.parse(localStorage.getItem(String(REACT_APP_USER)) ?? '{}');
  const { userType } = user;
  const mountContractorOptions = (): OptionProps[] => {
    const options: OptionProps[] = [];
    if (contractors && contractors.length > 0) {
      contractors.forEach(data => {
        options.push({
          value: data.id,
          label: data.name,
        });
      });
    }
    return options;
  };

  const mountPixKeyOptions = (): OptionProps[] => {
    const options: OptionProps[] = [];
    if (contractors && contractors.length > 0) {
      contractors.forEach(data => {
        if (data.id === formData[FormInput.contractor]) {
          if (data.pixKey && data.pixKey.length > 0) {
            data.pixKey.forEach(pix => {
              options.push({
                value: pix.id,
                label: `${pix.bank.fullName} - ${pix.key}`,
              });
            });
          }
        }
      });
    }
    return options;
  };

  useEffect(() => {
    if (userType === UserType.CONTRACTOR || (contractors && contractors.length === 1)) {
      onChangeFormInput(FormInput.contractor)(contractors[0].id);
    }
  }, [userType, contractors]);

  return (
    <Form
      noValidate={true}
      onSubmit={(e): void => {
        e.preventDefault();
      }}
    >
      {userType === UserType.Employee && (
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <SelectCustom
                name="contractor"
                label="Contratante"
                placeholder="Digite ou selecione o contratante"
                onChange={e => {
                  onChangeFormInput(FormInput.contractor)(e?.value as string);
                }}
                error={formErrors.contractor && formErrors.contractor[0]}
                value={formData[FormInput.contractor]}
                options={mountContractorOptions()}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={8}>
          {formData[FormInput.contractor] && formData[FormInput.contractor].trim().length > 1 && (
            <FormGroup className="mb-2">
              <SelectCustom
                name="pixKey"
                label="Chave PIX"
                placeholder="Digite ou selecione o PIX"
                onChange={e => {
                  onChangeFormInput(FormInput.pixKey)(e?.value as string);
                }}
                error={formErrors.pixKey && formErrors.pixKey[0]}
                value={formData[FormInput.pixKey]}
                options={mountPixKeyOptions()}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <FormGroup className="mb-2">
            <InputText
              name="transferValue"
              label="Valor"
              placeholder="Digite o valor"
              value={formData[FormInput.transferValue]}
              onChange={e => onChangeFormInput(FormInput.transferValue)(e.target.value)}
              error={formErrors.transferValue && formErrors.transferValue[0]}
              disabled={!formData[FormInput.pixKey]}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
