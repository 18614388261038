import React, { useEffect, useState } from 'react';
import api, { AxiosError } from '@/services/api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { unmask, updateMask } from '@/helpers/masks/cashNumber';
import Contractor from '@/model/Contractor';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import { PixKey } from '@/model/PixKey';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { ProducerReportMain } from '@/model/ProducerReportMain';
import { FormInput, ShouldShowModal, States, UrlParams } from '../types';
import { ProducerReportContainer } from './ui';
import { ConfirmContent } from '../components/ConfirmContent';
import { ConfirmAdvanceContent } from '../components/ConfirmAdvanceContent';

export const ProducerReportScreen: React.FC = (): JSX.Element => {
  const params = useParams<UrlParams>();
  const [state, setState] = useState<States>(States.default);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.transfer);
  const [producerReportMain, setProducerReportMain] = useState<ProducerReportMain>();
  const [transfers, setTransfers] = useState<EventMoneyTransfer[]>([]);
  const [contractors, setContractors] = useState<Contractor[]>([]);
  const [eventMoneyTransfer, setEventMoneyTransfer] = useState<EventMoneyTransfer>();

  const { visible, title, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const handleOnFetch = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<ProducerReportMain>(`/event/report/${params.id}/producer`);
      if (data) {
        setProducerReportMain(data);
      }
      const response = await api.get<EventMoneyTransfer[]>(
        `/event-money-transfer/find/${params.id}`,
      );
      if (response && response.data) {
        setTransfers(response.data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
    setState(States.default);
  };

  const { formData, formErrors, onChangeFormInput, isFormValid, resetForm } = useForm({
    initialData: {
      contractor: '',
      pixKey: '',
      transferValue: '',
    },
    validators: {
      contractor: [validators.required],
      pixKey: [validators.required],
      transferValue: [validators.required],
    },
    formatters: {
      transferValue: updateMask,
    },
  });

  const handleOnTransfer = async (): Promise<void> => {
    if (isFormValid()) {
      const transferValue = Number(unmask(formData[FormInput.transferValue]));
      if (!producerReportMain || Number(producerReportMain.summary.balance) < transferValue) {
        const msg = `Valor disponível (${toCurrency(
          producerReportMain ? producerReportMain.summary.balance : 0,
        )}) menor que o valor solicitado (${toCurrency(transferValue)})`;
        toast.error(msg);
      } else {
        try {
          setState(States.loading);
          const payload = {
            contractor: { id: formData[FormInput.contractor] } as Contractor,
            pixKey: { id: formData[FormInput.pixKey] } as PixKey,
            transferValue,
          } as EventMoneyTransfer;
          await api.post(`/event-money-transfer/${params.id}`, payload);
          await handleOnFetch();
          resetForm();
          setContractors([]);
          onToggle();
          confirmDelete.hide();
        } catch (error) {
          const err = error as AxiosError;
          toast.error(err.message);
        } finally {
          setState(States.default);
        }
      }
    }
  };

  const handleOnShowConfirm = (): void => {
    confirmDelete.show({
      title: '',
      children: <ConfirmContent />,
      actions: [
        {
          title: 'Fechar',
          theme: 'noneBorder',
          onClick: (): void => confirmDelete.hide(),
        },
        {
          title: 'Confirmar',
          onClick: (): Promise<void> => handleOnTransfer(),
        },
      ],
    });
  };

  const handleOnAdvance = async (): Promise<void> => {
    if (isFormValid()) {
      try {
        setState(States.loading);
        const transferValue = Number(unmask(formData[FormInput.transferValue]));
        const payload = {
          contractor: { id: formData[FormInput.contractor] } as Contractor,
          pixKey: { id: formData[FormInput.pixKey] } as PixKey,
          transferValue,
        } as EventMoneyTransfer;
        await api.post(`/event-money-transfer/advance/${params.id}`, payload);
        await handleOnFetch();
        resetForm();
        setContractors([]);
        onToggle();
        confirmDelete.hide();
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  const handleOnShowConfirmAdvance = (): void => {
    confirmDelete.show({
      title: '',
      children: <ConfirmAdvanceContent />,
      actions: [
        {
          title: 'Fechar',
          theme: 'noneBorder',
          onClick: (): void => confirmDelete.hide(),
        },
        {
          title: 'Confirmar',
          onClick: (): Promise<void> => handleOnAdvance(),
        },
      ],
    });
  };

  const handleOnGetContractors = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Contractor[]>(`/event-money-transfer/contractor/${params.id}`);
      setContractors(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnGetEventMoneyTransfer = async (selectedId: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventMoneyTransfer>(`/event-money-transfer/${selectedId}`);
      setEventMoneyTransfer(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    selectedId,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    selectedId?: string;
  }): Promise<void> => {
    if (value === ShouldShowModal.transfer) {
      await handleOnGetContractors();
    } else if (value === ShouldShowModal.detail) {
      if (selectedId) {
        await handleOnGetEventMoneyTransfer(selectedId);
      }
    }
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
  };

  const handleOnDownload = (attachmentUrl: string): void => {
    window.open(attachmentUrl);
  };

  useEffect(() => {
    handleOnFetch();
  }, []);

  return (
    <ProducerReportContainer
      state={state}
      producerReportMain={producerReportMain}
      transfers={transfers}
      visible={visible}
      title={title}
      onToggle={onToggle}
      formData={formData}
      formErrors={formErrors}
      onChangeFormInput={onChangeFormInput}
      shouldShowModal={shouldShowModal}
      contractors={contractors}
      onTransfer={handleOnShowConfirm}
      onAdvance={handleOnShowConfirmAdvance}
      onShouldShowModal={handleOnShouldShowModal}
      eventMoneyTransfer={eventMoneyTransfer}
      onDownload={handleOnDownload}
    />
  );
};
