/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { IncomeContainer, ShouldShowModal, States } from '@/features/eventClose/screens/Income/ui';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '@/services/api';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { unmask, updateMask } from '@/helpers/masks/cashNumber';
import { NameFiles } from '@/features/events/types';
import { DeleteContent } from '@/components/DeleteContent';
import { useConfirmDelete } from '@/hooks/useConfirmDelete';
import { FormInputName as FormInputNameIncome } from '@/features/eventClose/components/IncomeManualEntriesRegister';
import EventIncome from '@/model/EventIncome';
import EventAttachment from '@/model/EventAttachment';
import EventCloseIncomeMain from '@/model/EventCloseIncomeMain';
import { EventCloseIncome } from '@/model/EventCloseIncome';

export const IncomeScreen: React.FC = (): JSX.Element => {
  const { id: eventId } = useParams<{ id: string }>();
  const [state, setState] = useState<States>(States.default);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(
    ShouldShowModal.incomeRegister,
  );
  const [incomeFooter, setIncomeFooter] = useState<EventCloseIncomeMain>();
  const [incomeAttachments, setIncomeAttachments] = useState<EventAttachment[]>([]);
  const [nameFiles, setNameFiles] = useState<NameFiles>({});
  const [income, setIncome] = useState<EventIncome>();

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const confirmDelete = useConfirmDelete();

  const handleGetIncome = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get(`/event-close/${eventId}/income`);
      setIncomeFooter(data);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const {
    formData: formDataIncome,
    formErrors: formErrorsIncome,
    onChangeFormInput: onChangeFormInputIncome,
    isFormValid: isFormValidIncome,
    resetForm: resetFormIncome,
  } = useForm({
    initialData: {
      description: '',
      value: '',
      eventExpenseType: '',
    },
    validators: {
      description: [validators.required],
      value: [validators.required],
      eventExpenseType: [validators.required],
    },
    formatters: {
      value: updateMask,
    },
  });

  const handleGetIncomeDetailsList = async (selectedIncome: EventCloseIncome): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventIncome>(
        `/event/close/${eventId}/income/${selectedIncome.id}`,
      );
      setIncome(data);
      onChangeFormInputIncome(FormInputNameIncome.description)(data.description);
      onChangeFormInputIncome(FormInputNameIncome.value)(`${data.totalValue}`);
      onChangeFormInputIncome(FormInputNameIncome.eventExpenseType)(`${data.eventExpenseType}`);
      if (data.attachments && data.attachments.length > 0) {
        setIncomeAttachments(data.attachments);
      } else {
        setIncomeAttachments([]);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const controllerFormIncome = {
    formData: formDataIncome,
    formErrors: formErrorsIncome,
    onChangeFormInput: onChangeFormInputIncome,
  };

  const handleOnChangeFileInput =
    (inputName: string, index: number) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            // name files to index
            const newNameFiles = { ...nameFiles };
            newNameFiles[index] = file.name;
            setNameFiles(newNameFiles);
            // set income Attachments to index
            const newFormValues = [...incomeAttachments];
            newFormValues[index].fileURL = base64;
            setIncomeAttachments(newFormValues);
          }
        };
      } else {
        toast.error('Erro ao carregar arquivo');
      }
    };

  const handleDeleteIncome = async (incomeSelected: EventCloseIncome): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(`/event/close/${eventId}/income/${incomeSelected.id}`);
      toast.success('Anexo excluído com sucesso');
      confirmDelete.hide();
      handleGetIncome();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnShowDelete = (itemSelected: EventCloseIncome): void => {
    confirmDelete.show({
      title: '',
      children: <DeleteContent />,
      actions: [
        {
          title: 'Não, quero manter',
          theme: 'noneBorder',
          onClick: (): void => confirmDelete.hide(),
        },
        {
          title: 'Sim, quero excluir',
          onClick: (): Promise<void> => handleDeleteIncome(itemSelected),
        },
      ],
    });
  };

  const handleDeleteIncomeAttachments = async (
    incomeAttachmentSelected: EventAttachment,
  ): Promise<void> => {
    try {
      setState(States.loading);
      await api.delete(
        `/event/close/${eventId}/income/${income?.id}/attachment/${incomeAttachmentSelected.id}`,
      );
      toast.success('Anexo excluído com sucesso');
      confirmDelete.hide();
      if (income) {
        handleGetIncomeDetailsList({ id: income.id } as EventCloseIncome);
      }
      handleGetIncome();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const controllerInputAppendIncomeAttachments = {
    nameFiles,
    onChangeFileInput: handleOnChangeFileInput,
    incomeAttachments,
    setIncomeAttachments,
    handleAddIncomeAttachments(): void {
      setIncomeAttachments([
        ...incomeAttachments,
        {
          id: '',
          description: '',
          fileURL: '',
        },
      ]);
    },
    handleChangeIncomeAttachments(inputName: string, index: number, value: string): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newFormValues = [...incomeAttachments] as any;
      newFormValues[index][inputName] = value;
      setIncomeAttachments(newFormValues);
    },
    handleRemoveIncomeAttachments(index: number): void {
      const values = [...incomeAttachments];
      values.splice(index, 1);
      setIncomeAttachments(values);
      // clear name to index
      const newNameFiles = { ...nameFiles };
      delete newNameFiles[index];
      setNameFiles(newNameFiles);
    },
    handleDeleteIncomeAttachments,
    handleOnShowDelete,
  };

  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
    selectedIncome,
  }: {
    value: ShouldShowModal;
    newTitleModal: string;
    selectedIncome?: EventCloseIncome;
  }): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();
    if (selectedIncome) {
      handleGetIncomeDetailsList(selectedIncome);
    }
  };

  const handleOnSaveIncome = async (): Promise<void> => {
    try {
      setState(States.loading);
      const payloadIcomeAttachments = incomeAttachments.map(incomeAttachment => ({
        id: incomeAttachment?.id,
        description: incomeAttachment.description,
        fileURL: incomeAttachment.fileURL,
      }));

      if (isFormValidIncome()) {
        const payload = {
          id: income?.id,
          description: formDataIncome[FormInputNameIncome.description],
          totalValue: Number(unmask(formDataIncome[FormInputNameIncome.value])),
          eventExpenseType: Number(formDataIncome[FormInputNameIncome.eventExpenseType]),
        } as EventIncome;

        if (income && income.id) {
          payload.id = income.id;
        }

        const { data } = await api.post(`/event/close/${eventId}/income`, payload);

        for (let index = 0; index < payloadIcomeAttachments.length; index += 1) {
          const incomeAttachment = payloadIcomeAttachments[index];
          // eslint-disable-next-line no-await-in-loop
          await api.post(`/event/close/${eventId}/income/${data?.id}/attachment`, incomeAttachment);
        }

        resetFormIncome();
        toast.success('Registro salvo com sucesso!');

        onToggle();
        handleGetIncome();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  useEffect(() => {
    handleGetIncome();
  }, []);

  return (
    <IncomeContainer
      state={state}
      incomeFooter={incomeFooter}
      title={title}
      visible={visible}
      onToggle={onToggle}
      onShouldShowModal={handleOnShouldShowModal}
      shouldShowModal={shouldShowModal}
      formIncome={controllerFormIncome}
      controllerInputAppendIncomeAttachments={controllerInputAppendIncomeAttachments}
      onSaveIncome={handleOnSaveIncome}
    />
  );
};
