import { Dialog, Loading, SelectCustom, Button } from '@/components';
import { ActionProps } from '@/components/Dialog';
import { CustomTable } from '@/components/Table';
import { MapContent } from '@/features/createSale/components/MapContent';
import { States } from '@/helpers/common/states';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import ChartObject from '@/model/ChartObject';
import ChartSelectedTicketType from '@/model/ChartSelectedTicketType';
import CreateOrderAdmResponse from '@/model/CreateOrderAdmResponse';
import Event from '@/model/Event';
import EventMap from '@/model/EventMap';
import EventSiteGet from '@/model/EventSiteGet';
import { SelectedObject } from '@/model/SelectedObject';
import EventMapTicket from '@/model/EventMapTicket';
import { colors } from '@/styles/colors';
import dayjs from 'dayjs';
import React from 'react';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { Col, Container, FormGroup, Row } from 'reactstrap';
import {
  FormInputName,
  LockedTables,
  OnShouldShowModalProps,
  ShouldShowModal,
} from '../../../types';

export interface TableFormData {
  eventId: string;
  sectionId: string;
  code: string;
  paymentForm: string;
  showUserData: string;
}

interface StateProps {
  state: States;
  title: React.ReactNode | string;
  events: Event[];
  event?: EventSiteGet;
  eventMap?: EventMap;
  eventKey?: string;
  selectedObjects: SelectedObject[];
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  orderResponse?: CreateOrderAdmResponse;
  formData: FormData;
  formErrors: FormErrors;
  lockedTables: LockedTables[];
}

interface DispatchProps {
  clearSelectedObjects: () => void;
  onObjectSelected: (object: ChartObject, selectedTicketType: ChartSelectedTicketType) => void;
  onObjectDeselected: (object: ChartObject, selectedTicketType: ChartSelectedTicketType) => void;
  //   onOpenModal: (props: OnShouldShowModalTab) => void;
  onToggle: () => void;
  //   getEventId: (name: string, index: number) => string;
  //   openMap: (index: number) => void;
  //   getSectionId: (name: string, index: number) => string;
  onSubmitTableBlock: () => void;
  onUnlockTable: (idMap: string, labelUnlock: string, eventKeyUnlock: string) => void;
  onChangeFormInput: OnChangeFormInput;
  onSelectEvent: (eventId: string) => void;
  onShouldShowModal: (props: OnShouldShowModalProps) => void;
}

type Props = StateProps & DispatchProps;

export const TableBlockUI: React.FC<Props> = ({
  title,
  state,
  events,
  event,
  eventMap,
  eventKey,
  selectedObjects,
  visible,
  shouldShowModal,
  lockedTables,
  formErrors,
  formData,
  onObjectSelected,
  onObjectDeselected,
  onToggle,
  onSubmitTableBlock,
  onUnlockTable,
  onChangeFormInput,
  onSelectEvent,
  onShouldShowModal,
}): JSX.Element => {
  const renderActionDialogToClose: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };
  const getTickets = (sectionId: string): EventMapTicket[] => {
    const tickets: EventMapTicket[] = [];
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let s = 0; s < eventMap.sections.length; s++) {
        if (eventMap.sections[s].section.id === sectionId) {
          if (eventMap.sections[s].tickets && eventMap.sections[s].tickets.length > 0) {
            eventMap.sections[s].tickets.forEach(data => {
              tickets.push(data);
            });
          }
          break;
        }
      }
    }
    return tickets;
  };

  console.log('event', event);

  const mountValue = (unitValue: number, feeValue: number): string => {
    let value = toCurrency(unitValue);
    if (feeValue && feeValue > 0) {
      const calc = Number(unitValue) * Number(feeValue) - Number(unitValue);
      value += ` + Taxa ${toCurrency(calc)}`;
    }
    return value;
  };

  //   const getEventKey = (ticketId: string): string => {
  //     let value = '';
  //     if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
  //       // eslint-disable-next-line no-plusplus
  //       for (let s = 0; s < eventMap.sections.length; s++) {
  //         if (eventMap.sections[s].tickets && eventMap.sections[s].tickets.length > 0) {
  //           // eslint-disable-next-line no-plusplus
  //           for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
  //             if (eventMap.sections[s].tickets[t].ticket.id === ticketId) {
  //               value = eventMap.sections[s].tickets[t].eventKey;
  //               break;
  //             }
  //           }
  //         }
  //         if (value.length > 0) {
  //           break;
  //         }
  //       }
  //     }
  //     return value;
  //   };
  return (
    <React.Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.map]: renderActionDialogToClose,
          }[shouldShowModal],
          {
            [ShouldShowModal.map]: {},
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.map]: (
              <MapContent
                eventMap={eventMap}
                eventKey={eventKey}
                selectedObjects={selectedObjects}
                onObjectSelected={onObjectSelected}
                onObjectDeselected={onObjectDeselected}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="pageTitle d-flex mb-5">
          <Link to={`${process.env.PUBLIC_URL}/dashboard/event`}>
            <ArrowLeft color={colors.black} className="arrow-left" />
          </Link>
          <h5 className="ml-3 mb-0 mt-2 pageTitle">Bloquear mesas</h5>
        </div>
        <h5 className="mb-2 border-bottom-title mb-5">Selecionar</h5>
        <Row>
          <Col md={8}>
            <FormGroup className="mb-2">
              <SelectCustom
                name="eventId"
                label="Evento"
                placeholder="Digite ou selecione o evento"
                onChange={e => {
                  onChangeFormInput(FormInputName.eventId)(e?.value as string);
                  onSelectEvent(e?.value as string);
                }}
                error={formErrors.eventId && formErrors.eventId[0]}
                value={formData[FormInputName.eventId]}
                options={events.map(item => ({
                  value: item.id,
                  label: `${item.name} - ${dayjs(item.startDate).format('DD/MM/YYYY')}`,
                }))}
              />
            </FormGroup>
          </Col>
        </Row>
        {eventMap && eventMap.sections && eventMap.sections.length > 0 && (
          <>
            <Row>
              <Col md={8}>
                <FormGroup className="mb-2">
                  <SelectCustom
                    name="sectionId"
                    label="Setor"
                    placeholder="Digite ou selecione o setor"
                    onChange={e => {
                      onChangeFormInput(FormInputName.sectionId)(e?.value as string);
                    }}
                    error={formErrors.sectionId && formErrors.sectionId[0]}
                    value={formData[FormInputName.sectionId]}
                    options={eventMap.sections.map(item => ({
                      value: item.section.id,
                      label: item.section.name,
                    }))}
                  />
                </FormGroup>
              </Col>
            </Row>
            {formData[FormInputName.sectionId] &&
              formData[FormInputName.sectionId].trim().length > 0 && (
                <Row>
                  <Col md={8}>
                    <CustomTable
                      columns={[
                        {
                          name: 'Ingresso',
                          selector: row => row.name,
                        },
                        {
                          name: 'Valor',
                          selector: row => row.unitValue,
                        },
                        {
                          name: '',
                          selector: row => row.actions,
                          right: true,
                        },
                      ]}
                      data={getTickets(formData[FormInputName.sectionId]).map(data => {
                        const value = {
                          name: data.ticket.name,
                          unitValue: mountValue(data.ticket.unitValue, data.ticket.fee),
                          actions: (
                            <button
                              style={{
                                display: 'inline-block',
                                fontWeight: 400,
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                verticalAlign: 'middle',
                                userSelect: 'none',
                                border: '1px solid transparent',
                                borderRadius: '0.25rem',
                                transition:
                                  'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                                color: '#fff',
                                backgroundColor: '#007bff',
                                borderColor: '#007bff',
                              }}
                              onClick={(): void =>
                                onShouldShowModal({
                                  value: ShouldShowModal.map,
                                  newTitleModal: 'Mapa',
                                  eventKey: data.eventKey,
                                  ticketId: data.ticket.id,
                                })
                              }
                            >
                              Selecionar
                            </button>
                          ),
                          total: toCurrency(data.ticket.totalValue || 0),
                        };
                        return value;
                      })}
                      theme="secondary"
                      progressPending={false}
                      numberRowsPerPage={1000}
                    />
                  </Col>
                </Row>
              )}
          </>
        )}
        <h5 className="mb-2 border-bottom-title mb-5">Mesas Selecionadas</h5>
        <Row>
          <Col md={12}>
            {selectedObjects && selectedObjects.length > 0 ? (
              <>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '10px 0 20px 0',
                    color: '#4b4b4b',
                  }}
                >
                  {selectedObjects.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: '10px',
                        marginRight: '10px',
                        paddingRight: '10px',
                        borderRight: '1px solid #4b4b4b',
                      }}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
                <Row>
                  <Col md={8}>
                    <div className="footer-register-event">
                      <Button
                        theme="red"
                        size="lg"
                        title="Bloquear"
                        onClick={onSubmitTableBlock}
                        style={{ marginRight: 0 }}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <div style={{ padding: '10px 0 20px 0', color: '#A5A5A5' }}>
                  Você ainda não selecionou nenhuma mesa para bloqueio.
                </div>
                <div style={{ color: '#A5A5A5', paddingBottom: '30px' }}>
                  As mesas selecionadas serão exibidas na lista de pedidos.
                </div>
              </>
            )}
          </Col>
        </Row>
        {lockedTables && lockedTables.length > 0 && (
          <>
            <h5 className="mb-2 border-bottom-title mb-5">Mesas Bloqueadas</h5>
            <Row>
              <Col md={12}>
                {lockedTables.map((lockedTable, sectionIndex) => (
                  <div key={sectionIndex}>
                    <div
                      key={sectionIndex}
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        borderBottom: '2px solid #cecece',
                        paddingBottom: '10px',
                      }}
                    >
                      <div style={{ flex: 1 }}>{lockedTable.label}</div>
                      <div className="footer-register-event">
                        <Link
                          to="#"
                          onClick={() =>
                            onUnlockTable(
                              lockedTable.eventID,
                              lockedTable.label,
                              lockedTable.eventKey,
                            )
                          }
                          style={{
                            color: '#068515',
                            textDecoration: 'none',
                            padding: '10px 20px',
                            border: '1px solid #068515',
                            borderRadius: '4px',
                            display: 'inline-block',
                            textAlign: 'center',
                          }}
                        >
                          Desbloquear
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </>
        )}
      </Container>
    </React.Fragment>
  );
};
