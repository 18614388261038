import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { EventCloseExpense } from '@/model/EventCloseExpense';
import EventExpenseType from '@/model/EventExpenseType';
import { toCurrency } from '@/helpers/masks/toCurrency';
import download from '@/assets/images/svg/download.svg';

interface DetailManualContentProps {
  data?: EventCloseExpense;
}

export const DetailManualContent: React.FC<DetailManualContentProps> = ({ data }): JSX.Element => {
  const getEventExpenseType = (type: EventExpenseType): string => {
    let value = '';
    if (type === EventExpenseType.GENERAL) {
      value = 'Geral';
    } else if (type === EventExpenseType.TICKET) {
      value = 'Ingresso';
    } else if (type === EventExpenseType.PRODUCT) {
      value = 'Produto';
    }
    return value;
  };
  return (
    <>
      {data && (
        <div>
          <Row>
            <Col md={12}>
              <label className={'input-label m-0'}>Evento</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>{data.eventName}</Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col md={12}>
              <label className={'input-label m-0'}>Descrição</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>{data.description}</Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col md={12}>
              <label className={'input-label m-0'}>Tipo</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>{getEventExpenseType(data.eventExpenseType)}</Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col md={12}>
              <label className={'input-label m-0'}>Data</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>{dayjs(data.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Col>
          </Row>
          <Row style={{ marginTop: '30px' }}>
            <Col md={12}>
              <label className={'input-label m-0'}>Valor</label>
            </Col>
          </Row>
          <Row>
            <Col md={12}>{toCurrency(data.value)}</Col>
          </Row>
          {data.userName && (
            <Fragment>
              <Row style={{ marginTop: '30px' }}>
                <Col md={12}>
                  <label className={'input-label m-0'}>Usuário</label>
                </Col>
              </Row>
              <Row>
                <Col md={12}>{data.userName}</Col>
              </Row>
            </Fragment>
          )}
          {data.attachments &&
            data.attachments.length > 0 &&
            data.attachments.map(attachment => (
              <Fragment key={attachment.id}>
                <Row style={{ marginTop: '30px' }}>
                  <Col md={12}>
                    <label className={'input-label m-0'}>Comprovante</label>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <img src={download} alt="" onClick={() => window.open(attachment.fileURL)} />
                  </Col>
                </Row>
              </Fragment>
            ))}
        </div>
      )}
    </>
  );
};
