import React from 'react';
import { EventSummaryData } from '@/model/EventSummaryData';
import { DataList } from '@/components/DataList';
import { toPercentage } from '@/helpers/common/amount';

interface Props {
  title: string;
  data: EventSummaryData[];
}

export const SummayDataContent: React.FC<Props> = ({ title, data }): JSX.Element => (
  <>
    <h5 className="mb-2 border-bottom-title mb-5 container-event">{title}</h5>
    {data.map((item, index) => (
      <div style={{ overflow: 'auto' }} key={index}>
        <div className="mb-4">
          <div className="mb-2 text-darkgray-regular">Nome:</div>
          <div>{item?.name || '--'}</div>
        </div>
        {item.physicalSale && (
          <div className="d-grid">
            <p>Venda Física</p>
            <div
              className="mb-4"
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                columnGap: '30px',
              }}
            >
              <DataList
                data={[
                  {
                    title: 'Permite Cartão:',
                    content: item.physicalSale.allowCreditCardPayment ? 'Sim' : 'Não',
                  },
                  {
                    title: 'Débito:',
                    content: item.physicalSale.debit ? toPercentage(item.physicalSale.debit) : '--',
                  },
                  {
                    title: 'Crédito:',
                    content: item.physicalSale.credit
                      ? toPercentage(item.physicalSale.credit)
                      : '--',
                  },
                  {
                    title: 'Boleto:',
                    content: item.physicalSale.bankSlip
                      ? toPercentage(item.physicalSale.bankSlip)
                      : '--',
                  },
                  {
                    title: 'PIX:',
                    content: item.physicalSale.pix ? toPercentage(item.physicalSale.pix) : '--',
                  },
                  {
                    title: 'TX. Adm:',
                    content: item.physicalSale.administrateTax
                      ? toPercentage(item.physicalSale.administrateTax)
                      : '--',
                  },
                  {
                    title: 'Parcelas:',
                    content: item.physicalSale.installments ?? '--',
                  },
                  {
                    title: 'TX. Parcelas:',
                    content: item.physicalSale.fee ? toPercentage(item.physicalSale.fee) : '--',
                  },
                ]}
              />
            </div>
          </div>
        )}

        {item.websiteSale && (
          <div className="d-grid">
            <p>Venda Online</p>
            <div
              className="mb-4"
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                columnGap: '30px',
              }}
            >
              <DataList
                data={[
                  {
                    title: 'Permite Cartão:',
                    content: item.websiteSale.allowCreditCardPayment ? 'Sim' : 'Não',
                  },
                  {
                    title: 'Débito:',
                    content: item.websiteSale.debit ? toPercentage(item.websiteSale.debit) : '--',
                  },
                  {
                    title: 'Crédito:',
                    content: item.websiteSale.credit ? toPercentage(item.websiteSale.credit) : '--',
                  },
                  {
                    title: 'Boleto:',
                    content: item.websiteSale.bankSlip
                      ? toPercentage(item.websiteSale.bankSlip)
                      : '--',
                  },
                  {
                    title: 'PIX:',
                    content: item.websiteSale.pix ? toPercentage(item.websiteSale.pix) : '--',
                  },
                  {
                    title: 'TX. Adm:',
                    content: item.websiteSale.administrateTax
                      ? toPercentage(item.websiteSale.administrateTax)
                      : '--',
                  },
                  {
                    title: 'Parcelas:',
                    content: item.websiteSale.installments ?? '--',
                  },
                  {
                    title: 'TX. Parcelas:',
                    content: item.websiteSale.fee ? toPercentage(item.websiteSale.fee) : '--',
                  },
                ]}
              />
            </div>
          </div>
        )}

        {data && data.length - 1 !== index && (
          <div key={index} className="mb-5 mt-5" style={{ borderBottom: 'solid 1px #D9D9D9' }} />
        )}
      </div>
    ))}
  </>
);
