import IconDocument from '@/assets/images/svg/IconDocument';
import React from 'react';
import './style.css';

interface InputFileProps {
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileName?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  ref?: React.Ref<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  label?: string | React.ReactNode;
  wrapperClass?: string;
  accept?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  refInput?: React.Ref<HTMLInputElement>;
}
// export default InputFile;

export const InputFileSupport: React.FC<InputFileProps> = ({
  name,
  error,
  wrapperClass,
  accept,
  onChange,
  refInput,
}: InputFileProps) => (
  <div className={`flex-column input-component ${wrapperClass} ${error ? 'input-error' : ''}`}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 10px',
        // background: '#f6f6f6',
        borderRadius: '5px',
        height: '62px',
        border: error ? '1px solid #D8413A' : 'none',
      }}
    >
      <label htmlFor={name} className="button-label-file button-file ">
        <IconDocument />
      </label>
      <input
        type="file"
        accept={accept}
        name={name}
        id={name}
        onChange={onChange}
        style={{ display: 'none' }}
        className={`${error ? 'input-error' : ''}`}
        ref={refInput}
      />
    </div>
    {error && (
      <span role="alert" className="alert-error">
        {error}
      </span>
    )}
  </div>
);
