import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api, { AxiosError } from '@/services/api';
import { toast } from 'react-toastify';
import { EventSummary } from '@/model/EventSummary';
import { States } from '../../types';
import { ReleaseEventDetailUI } from './ui';

export type UrlParams = {
  id: string;
};
export const ReleaseEventDetailScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [event, setEvent] = useState<EventSummary>();
  const params = useParams<UrlParams>();
  const history = useHistory();

  const handleOnFetchEvent = async (id: string): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<EventSummary>(`/event/${id}/summary`);

      if (data) {
        setEvent(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const handleOnReleaseEvent = async (): Promise<void> => {
    try {
      setState(States.loading);
      if (event) {
        const payload = {
          name: event.name,
        };
        await api.patch<Event>(`/event/${event.id}/active`, payload);

        toast.success('Evento Liberado!');
        setTimeout(() => {
          history.push('/dashboard/event/release');
        }, 500);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  useEffect(() => {
    if (params.id) {
      handleOnFetchEvent(params.id);
    }
  }, [params]);
  return <ReleaseEventDetailUI state={state} event={event} onReleaseEvent={handleOnReleaseEvent} />;
};
