import React, { Fragment } from 'react';
import { Button, Dialog, Loading } from '@/components';
import { Container } from 'reactstrap';
import './style.scss';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { colors } from '@/styles/colors';
import { CustomTable } from '@/components/Table';
import { FooterCustom } from '@/components/FooterCustom';
import { IncomeManualEntriesRegister } from '@/features/eventClose/components/IncomeManualEntriesRegister';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import EventCloseIncomeMain from '@/model/EventCloseIncomeMain';
import { NameFiles } from '@/features/events/types';
import EventAttachment from '@/model/EventAttachment';
import { EventCloseIncome } from '@/model/EventCloseIncome';
import { ReactComponent as Pen } from '@/assets/images/svg/pen.svg';
import { ReactComponent as Trash } from '@/assets/images/svg/lixeira.svg';
import { columnsIncomeDetails } from './table';

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

interface IncomeProps {
  state: States;
  incomeFooter?: EventCloseIncomeMain;
  shouldShowModal: ShouldShowModal;
  title: string | React.ReactNode;
  visible: boolean;
  onToggle: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    selectedIncome,
  }: {
    value: ShouldShowModal;
    newTitleModal: string;
    selectedIncome?: EventCloseIncome;
  }) => void;

  formIncome: {
    formData: FormData;
    formErrors: FormErrors;
    onChangeFormInput: OnChangeFormInput;
  };
  controllerInputAppendIncomeAttachments: {
    nameFiles: NameFiles;
    onChangeFileInput: (inputName: string, index: number) => (file: File | undefined) => void;
    incomeAttachments: EventAttachment[];
    setIncomeAttachments: (eventAttachments: EventAttachment[]) => void;
    handleAddIncomeAttachments: () => void;
    handleChangeIncomeAttachments: (inputName: string, index: number, value: string) => void;
    handleRemoveIncomeAttachments: (index: number) => void;
    handleDeleteIncomeAttachments: (incomeAttachmentSelected: EventAttachment) => void;
    handleOnShowDelete: (itemSelected: EventCloseIncome) => void;
  };
  onSaveIncome: () => void;
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  incomeRegister = 'incomeRegister',
}

export const IncomeContainer: React.FC<IncomeProps> = ({
  state,
  incomeFooter,
  shouldShowModal,
  title,
  visible,
  onToggle,
  onShouldShowModal,
  formIncome,
  controllerInputAppendIncomeAttachments,
  onSaveIncome,
}) => {
  const { id: eventId } = useParams<{ id: string }>();
  const dataTableIncome =
    incomeFooter && incomeFooter.incomes && incomeFooter.incomes.length > 0
      ? incomeFooter.incomes.map((item, index) => ({
          id: index,
          item: index < 10 ? `0${index + 1}` : index + 1,
          description: item.description,
          value: item?.value?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }),
          actions: item.isIncome ? (
            <div className="mt-3 d-flex">
              <Pen
                className="mr-4 svg-icon action-icon"
                onClick={(): void =>
                  onShouldShowModal({
                    value: ShouldShowModal.incomeRegister,
                    newTitleModal: `${item.description}`,
                    selectedIncome: item,
                  })
                }
              />

              <Trash
                className="mr-2 svg-icon action-icon svg-icon-trash"
                onClick={() => controllerInputAppendIncomeAttachments.handleOnShowDelete(item)}
              />
            </div>
          ) : (
            ''
          ),
        }))
      : [];

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position="center"
        isContentWithCard={false}
        actions={[
          {
            title: 'Cancelar',
            onClick: (): void => onToggle(),
            theme: 'noneBorder',
          },
          {
            title: 'Inserir',
            onClick: onSaveIncome,
          },
        ]}
      >
        {
          {
            [ShouldShowModal.incomeRegister]: (
              <IncomeManualEntriesRegister
                formIncome={formIncome}
                controllerInputAppendIncomeAttachments={controllerInputAppendIncomeAttachments}
              />
            ),
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="pageTitle d-flex justify-content-between mb-5">
          <div className="d-flex">
            <Link to={`${process.env.PUBLIC_URL}/financial/event-close/${eventId}`}>
              <ArrowLeft color={colors.black} className="arrow-left" />
            </Link>
            <h5 className="ml-3 mb-0 pageTitle">Receitas</h5>
          </div>
          <div>
            <Button
              title="+ Inserir item"
              onClick={(): void => {
                onShouldShowModal({
                  value: ShouldShowModal.incomeRegister,
                  newTitleModal: `Inserir item`,
                });
              }}
            />
          </div>
        </div>
        <CustomTable
          columns={columnsIncomeDetails}
          data={dataTableIncome}
          numberRowsPerPage={10}
          theme="primary"
          progressPending={state === States.loading}
        />
      </Container>
      <FooterCustom
        data={[
          {
            title: 'Totais de ítens:',
            value: incomeFooter?.amount || 'Dado não encontrado',
          },
          {
            title: 'Total de receitas:',
            value:
              incomeFooter?.totalValue?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }) || 'Dado não encontrado',
          },
        ]}
      />
    </Fragment>
  );
};
