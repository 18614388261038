import { DataList } from '@/components/DataList';
import React, { Fragment } from 'react';
import { Button, Loading } from '@/components';
import { Container } from 'reactstrap';
import { EventSummary } from '@/model/EventSummary';
import dayjs from 'dayjs';
import { SummayDataContent } from '@/features/releaseEvent/components/SummayDataContent';
import { EventSummaryData } from '@/model/EventSummaryData';
import { toPercentage } from '@/helpers/common/amount';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { States } from '../../../types';

interface StateProps {
  state: States;
  event?: EventSummary;
}

interface DispatchProps {
  onReleaseEvent: () => void;
}

type Props = StateProps & DispatchProps;

export const ReleaseEventDetailUI: React.FC<Props> = ({
  state,
  event,
  onReleaseEvent,
}): JSX.Element => {
  const getEventType = (type: number): string => {
    switch (type) {
      case 0:
        return 'Mono';
      case 1:
        return 'Pai';
      case 2:
        return 'Filho';
      default:
        return '--';
    }
  };
  // Confirmação de dados
  const dataConfirmation = [
    {
      title: 'Nome do evento:',
      content: event?.name || '--',
    },
    {
      title: 'Tipo do evento:',
      content: getEventType(Number(event?.eventType)),
    },
    {
      title: 'Eventos filhos:',
      content: event?.childs?.map(child => child) || '--',
    },
    {
      title: 'Evento pai:',
      content: event?.fatherEvent || '--',
    },
    {
      title: 'Cidade / Estado:',
      content: event?.city && event.state ? `${event?.city} / ${event?.state}` : '--',
    },
    {
      title: 'Data e hora início:',
      content:
        event?.startDate !== undefined
          ? `${dayjs(event?.startDate).format('DD/MM/YYYY')} - ${
              String(event?.startDate).split('T')[1].slice(0, 5) ?? ''
            }`
          : '--',
    },
    {
      title: 'Data e hora fim:',
      content:
        event?.startDate !== undefined
          ? `${dayjs(event?.endDate).format('DD/MM/YYYY')} - ${
              String(event?.endDate).split('T')[1].slice(0, 5) ?? ''
            }`
          : '--',
    },
    {
      title: 'Empresa ou contratante:',
      content: event?.contractors[0] || '--',
    },
    {
      title: 'Artistas:',
      content: event?.artists[0] || '--',
    },
  ];

  const checkRender = (data: EventSummaryData[], online: boolean, physical: boolean): boolean => {
    let render = false;
    if (data && data.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < data.length; i++) {
        if (online && data[i].websiteSale) {
          render = true;
          break;
        }
        if (physical && data[i].physicalSale) {
          render = true;
          break;
        }
      }
    }
    return render;
  };

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Container className="mainContainer" fluid={true}>
        <div className="container-event">
          <h5 className="mb-2 border-bottom-title mb-5">Confirmação de dados</h5>
        </div>
        <div style={{ overflow: 'auto' }}>
          <DataList data={dataConfirmation} />
        </div>
        {event &&
          event.tickets &&
          event.tickets.length > 0 &&
          checkRender(event.tickets, true, true) && (
            <SummayDataContent title="Setores e Ingressos" data={event.tickets} />
          )}

        {event &&
          event.products &&
          event.products.length > 0 &&
          checkRender(event.products, false, true) && (
            <SummayDataContent title="Produtos" data={event.products} />
          )}

        {event && event.pdvs && event.pdvs.length > 0 && checkRender(event.pdvs, false, true) && (
          <SummayDataContent title="PDV" data={event.pdvs} />
        )}

        {event && event.poss && event.poss.length > 0 && checkRender(event.poss, false, true) && (
          <SummayDataContent title="PDV" data={event.poss} />
        )}

        {event && event.producer && (
          <>
            <h5 className="mb-2 border-bottom-title mb-5 container-event">Produtor</h5>

            <div style={{ overflow: 'auto' }}>
              <p>
                <h6>Ingresso</h6>
              </p>
              <div
                className="mb-4"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                  columnGap: '30px',
                }}
              >
                <DataList
                  data={[
                    {
                      title: 'PDV',
                      content: `${toPercentage(event.producer.pdv)} %`,
                    },
                    {
                      title: 'Crédito à vista',
                      content: `${toPercentage(event.producer.credit)} %`,
                    },
                    {
                      title: 'Crédito parcelado',
                      content: `${toPercentage(event.producer.installment)} %`,
                    },
                    {
                      title: 'Débito',
                      content: `${toPercentage(event.producer.debit)} %`,
                    },
                    {
                      title: 'PIX',
                      content: `${toPercentage(event.producer.pix)} %`,
                    },
                    {
                      title: 'Boleto',
                      content: `R$ ${toCurrency(event.producer.bankslip)}`,
                    },
                    {
                      title: 'Alimentação',
                      content: toCurrency(event.producer.food),
                    },
                    {
                      title: 'Técnicos',
                      content: toCurrency(event.producer.technical),
                    },
                    {
                      title: 'Viagem',
                      content: toCurrency(event.producer.trip),
                    },
                    {
                      title: 'Alug. POS',
                      content: toCurrency(event.producer.rentPos),
                    },
                  ]}
                />
              </div>
              <p>
                <h6>Bar</h6>
              </p>
              <div
                className="mb-4"
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                  columnGap: '30px',
                }}
              >
                <DataList
                  data={[
                    {
                      title: 'PDV',
                      content: `${toPercentage(event.producer.pdvProduct)} %`,
                    },
                    {
                      title: 'Crédito à vista',
                      content: `${toPercentage(event.producer.creditProduct)} %`,
                    },
                    {
                      title: 'Crédito parcelado',
                      content: `${toPercentage(event.producer.installmentProduct)} %`,
                    },
                    {
                      title: 'Débito',
                      content: `${toPercentage(event.producer.debitProduct)} %`,
                    },
                    {
                      title: 'PIX',
                      content: `${toPercentage(event.producer.pixProduct)} %`,
                    },
                    {
                      title: 'Boleto',
                      content: `R$ ${toCurrency(event.producer.bankSlipProduct)}`,
                    },
                    {
                      title: 'Alimentação',
                      content: toCurrency(event.producer.foodProduct),
                    },
                    {
                      title: 'Técnicos',
                      content: toCurrency(event.producer.technicalProduct),
                    },
                    {
                      title: 'Viagem',
                      content: toCurrency(event.producer.tripProduct),
                    },
                    {
                      title: 'Alug. POS',
                      content: toCurrency(event.producer.rentPosProduct),
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}

        <hr />
        <div className="footer-register-event">
          <Button
            title="Liberar evento"
            onClick={() => {
              onReleaseEvent();
            }}
          />
        </div>
      </Container>
    </Fragment>
  );
};
