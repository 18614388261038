import React from 'react';

const WarningIcon: React.FC<{ width?: number; height?: number; color?: string }> = ({
  width = 235,
  height = 212,
  color = '#E64F49',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 235 212"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M230.682 166.231L140.676 13.7968C135.6 5.19958 126.936 0.0665588 117.5 0.0665588C108.063 0.0665588 99.399 5.19958 94.3249 13.7968L4.31812 166.23C-1.08421 175.38 -1.4426 186.979 3.38585 196.502C8.21429 206.02 17.4523 211.933 27.4948 211.933H207.506C217.549 211.933 226.787 206.021 231.617 196.499C236.443 186.979 236.084 175.381 230.682 166.231ZM117.5 186.275C109.223 186.275 102.514 179.023 102.514 170.077C102.514 161.131 109.224 153.879 117.5 153.879C125.777 153.879 132.486 161.131 132.486 170.077C132.486 179.023 125.776 186.275 117.5 186.275ZM138.272 73.717L130.891 127.431C129.804 135.338 122.993 140.795 115.679 139.621C109.769 138.672 105.338 133.664 104.427 127.614L96.4117 74.0063C94.5457 61.5247 102.394 49.7713 113.942 47.7544C125.49 45.7375 136.364 54.2203 138.23 66.7019C138.581 69.0522 138.569 71.4786 138.272 73.717Z"
      fill={color}
    />
  </svg>
);

export default WarningIcon;
