import React from 'react';
import { Container } from 'reactstrap';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import Event from '@/model/Event';
import Page from '@/model/Page';
import { DropdonwFlags, Loading } from '@/components';
import { ColumnImage, ColumnStatus, CustomTable } from '@/components/Table';
import Pagination from '@/components/Utils/Pagination';
import EventStatus from '@/model/EventStatus';
import { colors } from '@/styles/colors';
import { columns, dataEventType, States } from '../../../types';

interface StateProps {
  state: States;
  page: Page<Event, Event>;
}

interface DispatchProps {
  onPaginationChange: (page: number) => void;
}

type Props = StateProps & DispatchProps;

export const ReleaseEventUI: React.FC<Props> = ({
  state,
  page,
  onPaginationChange,
}): JSX.Element => {
  const history = useHistory();
  const changeColorColumn = (status: EventStatus): string => {
    let color = colors.grey;
    switch (status) {
      case EventStatus.DRAFT:
        color = colors.lightBlue;
        break;
      case EventStatus.PENDING:
        color = colors.darkRed;
        break;
      case EventStatus.ACTIVE:
        color = colors.green;
        break;
      case EventStatus.REFUSED:
        color = colors.yellow;
        break;
      case EventStatus.BLOCKED:
        color = colors.red;
        break;
      case EventStatus.FINISHED:
        color = colors.lightGreen;
        break;
      case EventStatus.INACTIVE:
        color = colors.orange;
        break;
      case EventStatus.COMPLETED:
        color = colors.grey;
        break;
      default:
        color = colors.grey;
        break;
    }
    return color;
  };
  const dataTable = page.list
    ? page.list.map(event => ({
        id: event.id,
        image: (
          <ColumnStatus justify="right" statusColor={String(changeColorColumn(event.eventStatus))}>
            <ColumnImage srcImage={event.imageBase64} />
          </ColumnStatus>
        ),
        name: (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>{event.name}</div>
            {
              <DropdonwFlags
                className="mt-2"
                dataColumn={dataEventType.filter(value => value.id === event.eventType)}
              />
            }
          </div>
        ),
        city: `${event.address.city}/${event.address.state}`,
        startDate:
          event.startDate === null
            ? '-----'
            : // eslint-disable-next-line no-useless-concat
              `${dayjs(event.startDate).format('DD/MM/YYYY')} às ${String(event.startDate)
                .split('T')[1]
                .slice(0, 5)}`,
        endDate:
          event.endDate === null
            ? '-----'
            : `${dayjs(event.endDate).format('DD/MM/YYYY')} às ${String(event.endDate)
                .split('T')[1]
                .slice(0, 5)}`,
      }))
    : [];
  return (
    <>
      <Loading isVisible={state === States.loading} />

      <Container className="mainContainer" fluid={true}>
        <div
          className="d-flex justify-content-between event-page-title-container"
          style={{ paddingBottom: '30px' }}
        >
          <div className="pageTitle event-page-title" style={{ display: 'grid' }}>
            <h5 className="pageTitle" style={{ marginBottom: '1px' }}>
              Eventos aguardando liberação
            </h5>
          </div>
        </div>
        <CustomTable
          columns={columns}
          data={dataTable}
          numberRowsPerPage={10}
          getRowId={e => history.push(`/dashboard/event/release/detail/${e.id}`)}
          theme="primary"
          progressPending={false}
        />
        <Pagination
          currentPage={page.page}
          totalCount={page.total}
          pageSize={page.pageSize}
          onPageChange={onPaginationChange}
          total={page.total}
        />
      </Container>
    </>
  );
};
