import React from 'react';
import { Route } from '@/navigation/Route';
import { ReleaseEventScreen } from './screens/list';
import { ReleaseEventDetailScreen } from './screens/detail';

export const RELEASE_EVENT_ROUTES = {
  itself: '/dashboard/event/release',
  detail: '/dashboard/event/release/detail/:id',
};

export const ReleaseEventNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route exact path={RELEASE_EVENT_ROUTES.itself} component={ReleaseEventScreen} isPrivateRoute />
    <Route
      exact
      path={RELEASE_EVENT_ROUTES.detail}
      component={ReleaseEventDetailScreen}
      isPrivateRoute
    />
  </React.Fragment>
);
