import { Loading } from '@/components';
import { useDialog } from '@/hooks/useDialog';
import EventGeneralReport from '@/model/EventGeneralReport';
import EventMap from '@/model/EventMap';
import EventReportPayment from '@/model/EventReportPayment';
import api from '@/services/api';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ReportControllerProps } from '../../types';
import { GeneralSaleContent, ShouldShowModal } from './ui';

export interface GeneralSaleProps {
  generatePDF: () => void;
  onShowModal: () => void;
  payments: EventReportPayment;
  reportController: ReportControllerProps;
}

// eslint-disable-next-line no-shadow
export enum States {
  default = 'default',
  loading = 'loading',
}

export interface OnShouldShowModalProps {
  value: ShouldShowModal;
  newTitleModal: string | React.ReactNode;
  eventMap?: EventMap;
  eventKey?: string;
}

export const GeneralSale: React.FC<GeneralSaleProps> = ({
  generatePDF,
  onShowModal,
  payments,
  reportController,
}) => {
  const [state, setState] = useState<States>(States.default);
  const [eventMap, setEventMap] = useState<EventMap>();
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.map);
  const [eventKey, setEventKey] = useState<string>();
  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const { eventId, generalSale, setGeneralSale } = reportController;

  const handleGetGeneralSale = async (): Promise<void> => {
    if (generalSale === undefined) {
      try {
        setState(States.loading);
        const { data } = await api.get<EventGeneralReport>(
          `event/report/${eventId}/general?singleEvent=${reportController.singleEvent}`,
        );
        if (data) {
          setGeneralSale(data);
        }
        try {
          const responseMap = await api.get<EventMap>(`/table/map/${eventId}`);

          setEventMap(responseMap.data);
        } catch {
          console.error('Não foi possivel carragar mapa');
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setState(States.default);
      }
    }
  };

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    eventKey: eventKeySelected,
  }: OnShouldShowModalProps): Promise<void> => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    if (eventKeySelected) {
      setEventKey(eventKeySelected);
    }
    onToggle();
  };

  useEffect(() => {
    handleGetGeneralSale();
  }, []);

  return (
    <>
      <Loading isVisible={state === 'loading'} />
      <GeneralSaleContent
        eventMap={eventMap}
        eventKey={eventKey}
        title={title}
        visible={visible}
        onToggle={onToggle}
        generatePDF={generatePDF}
        onShowModal={onShowModal}
        payments={payments}
        generalSale={generalSale}
        onShouldShowModal={handleOnShouldShowModal}
        shouldShowModal={shouldShowModal}
      />
    </>
  );
};
