import WarningIcon from '@/assets/images/svg/alert';
import React from 'react';

export const ConfirmUpdate: React.FC = (): JSX.Element => (
  <div className="exclude-container">
    <WarningIcon width={40} height={40} color="#E64F49" />
    <div className="header-title-text modal__title">
      <h5 className="modal__confirmation-title">Você tem certeza que quer atualizar o status?</h5>
    </div>
  </div>
);
