/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import {
  SectorTicketMainSettingsContainer,
  ShouldShowModal,
  States,
} from '@/features/registerEvent/components/SectorTicketMainSettingsSreen/screens/ui';
import useForm from '@/hooks/useForm';
import validators from '@/helpers/validators';
import { NameFiles } from '@/features/registerEvent/types';
import { useDialog } from '@/hooks/useDialog';
import { toast } from 'react-toastify';
import api, { AxiosError } from '@/services/api';
import { FormInputName as FormInputNameToMainSettings } from '@/features/registerEvent/components/SectorTicketMainSettingsSreen/components/SectorTicketMainSettingsContent';
import { FormInputName as FormInputNameToSector } from '@/features/registerEvent/components/SectorTicketMainSettingsSreen/components/RegisterSectorContent';
import { FormInputName as FormInputNameToBatch } from '@/features/registerEvent/components/SectorTicketMainSettingsSreen/components/BatchContent';
import { convertToBoolean } from '@/helpers/common/convertToBoolean';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import Section from '@/model/Section';
import TicketBatch from '@/model/TicketBatch';
import {
  SectorTicketContainerProps,
  TabSectorTicketActionsProps,
} from '@/features/registerEvent/screens/SectorTicket/ui';

import { unmask } from '@/helpers/masks/cashNumber';
import { toPercentage } from '@/helpers/common/amount';
import EventTicketMainConfiguration from '@/model/EventTicketMainConfiguration';
import PrinterType from '@/model/PrinterType';
import { PrinterServer } from '@/model/PrinterServer';
import {
  batchActionsProps,
  batchStatesProps,
  formBatchsProps,
  formMainSettingsProps,
  formSectorProps,
  mainSettingsProps,
  modalConfigTicketMainSettingsProps,
  onShouldShowModalTicketMainSettingsProps,
  sectorActionsProps,
  sectorStatesProps,
} from '../types';

type UrlParams = {
  id: string;
};

export const SectorTicketMainSettingsScreen: React.FC<
  Pick<SectorTicketContainerProps, 'ticketStates'> &
    Pick<SectorTicketContainerProps, 'ticketStep'> &
    Omit<TabSectorTicketActionsProps, 'backTab'>
> = ({ ticketStates, ticketStep, nextTab, onFirstTab, reloadTickets }): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});

  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.sector);

  const [sector, setSector] = useState<Section>();
  const [sectorList, setSectorList] = useState<Section[]>([]);

  const [printerServers, setPrinterServers] = useState<PrinterServer[]>([]);

  const [batch, setBatch] = useState<TicketBatch>();
  const [batchList, setBatchList] = useState<TicketBatch[]>([]);

  const [hasHalfPrice, setHasHalfPrice] = useState<boolean>(false);

  const { title, visible, onChangeTitle, onToggle } = useDialog();
  const params = useParams<UrlParams>();

  const {
    formData: formDataMainSettings,
    formErrors: formErrorsMainSettings,
    onChangeFormInput: onChangeFormInputMainSettings,
    setErrors: setErrorsMainSettings,
    isFormValid: isFormValidMainSettings,
    resetForm: resetFormMainSettings,
  } = useForm({
    initialData: {
      name: '',
      eventSection: '',
      hasHalfPrice: 'false',
      percentageHalfPrice: '',
      amountHalfPrice: '',
      hasCourtesy: 'false',
      amountCourtesy: '',
      printLayoutBase64: '',
      printImageBase64: '',
      printerType: '0',
      printerServerId: '',
      copies: '',
      reprint: 'false',
      printBatchNumber: 'false',
      observation: '',
    },
    validators: {
      name: [validators.required],
      eventSection: [validators.required],
      hasHalfPrice: [validators.required],
      hasCourtesy: [validators.required],
      printerType: [validators.required],
    },
    formatters: {},
  });

  const {
    formData: formDataBatchs,
    formErrors: formErrorsBatchs,
    onChangeFormInput: onChangeFormInputBatchs,
    setErrors: setErrorsBatchs,
    isFormValid: isFormValidBatchs,
    resetForm: resetFormBatchs,
  } = useForm({
    initialData: {
      name: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      amount: '',
      unitValue: '',
      totalValue: '',
      percentageHalfPrice: '',
      amountHalfPrice: '',
      imageUrl: '',
    },
    validators: {
      name: [validators.required],
      startDate: [validators.required],
      endDate: [validators.required],
      startTime: [validators.required],
      endTime: [validators.required],
      amount: [validators.required],
      unitValue: [validators.required],
    },
    formatters: {},
  });

  const {
    formData: formDataSector,
    formErrors: formErrorsSector,
    onChangeFormInput: onChangeFormInputSector,
    isFormValid: isFormValidSector,
    resetForm: resetFormSector,
  } = useForm({
    initialData: {
      name: '',
    },
    validators: {
      name: [validators.required],
    },
    formatters: {},
  });

  // Change file input ---------------------------------------------------------
  const handleOnChangeFileInputMainSettings =
    (inputName: string) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputMainSettings(inputName)('');
            onChangeFormInputMainSettings(inputName)(base64);
          }
        };
      } else {
        setErrorsMainSettings({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };

  const handleOnChangeFileInputBatch =
    (inputName: string) =>
    (file: File | undefined): void => {
      // validate if file is image
      if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64 = reader.result?.toString();
          if (base64) {
            setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
            onChangeFormInputBatchs(inputName)('');
            onChangeFormInputBatchs(inputName)(base64);
          }
        };
      } else {
        setErrorsBatchs({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
      }
    };
  // Change file input ---------------------------------------------------------

  const handleFecthSectorList = async (): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.get<Section[]>(`/section/find`);
      // filter father event when event type is father

      setSectorList(data ?? []);

      const response = await api.get<PrinterServer[]>(`/event/ticket/server-printer/all`);
      if (response && response.data) {
        setPrinterServers(response.data);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnSaveSector = async (): Promise<void> => {
    try {
      setState(States.loading);
      if (isFormValidSector()) {
        const payload = {
          id: sector?.id,
          name: formDataSector[FormInputNameToSector.name],
          description: '-',
          imageBase64: '',
        };

        if (!payload.id) {
          delete payload.id;

          await api.post<Section>('/section', payload);
          toast.success('Setor cadastrado com sucesso!');
        } else {
          await api.put<Section>('/section', payload);
          toast.success('Setor atualizado com sucesso!');
        }

        onToggle();
        handleFecthSectorList();
        resetFormSector();
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.loading);
    }
  };

  const toPrinterType = (value: string): PrinterType => {
    let printerType = PrinterType.MACHINE;
    switch (value) {
      case '0':
        printerType = PrinterType.MACHINE;
        break;
      case '1':
        printerType = PrinterType.BLUETOOTH;
        break;
      case '2':
        printerType = PrinterType.USB110;
        break;
      case '3':
        printerType = PrinterType.WHATSAPP;
        break;
      case '4':
        printerType = PrinterType.USB100;
        break;
      default:
        printerType = PrinterType.MACHINE;
        break;
    }
    return printerType;
  };

  const handleOnSaveMainSettings = async ({ isBntNext }: { isBntNext: boolean }): Promise<void> => {
    try {
      setState(States.loading);
      const isFormValid = isFormValidMainSettings();
      if (
        isFormValid &&
        convertToBoolean(formDataMainSettings[FormInputNameToMainSettings.hasCourtesy])
      ) {
        const amountCourtesy = +formDataMainSettings[FormInputNameToMainSettings.amountCourtesy];
        if (amountCourtesy <= 0) {
          setErrorsMainSettings({
            [FormInputNameToMainSettings.amountCourtesy]: ['O valor deve ser maior que 0'],
          });
          return;
        }
      }

      if (isFormValid) {
        const payloadBatchs = batchList;

        // continue someone batchList is not array empty
        if (payloadBatchs.length === 0) {
          toast.warn('Adicione ao menos um lote para continuar');
          return;
        }

        const payload: EventTicketMainConfiguration = {
          id: ticketStates.ticket?.id as string,
          eventSection: {
            id: formDataMainSettings[FormInputNameToMainSettings.eventSection],
          } as Section,
          name: formDataMainSettings[FormInputNameToMainSettings.name],
          hasHalfPrice: convertToBoolean(
            formDataMainSettings[FormInputNameToMainSettings.hasHalfPrice],
          ),
          hasCourtesy: convertToBoolean(
            formDataMainSettings[FormInputNameToMainSettings.hasCourtesy],
          ),
          amountCourtesy: +formDataMainSettings[FormInputNameToMainSettings.amountCourtesy],
          printerType: toPrinterType(formDataMainSettings[FormInputNameToMainSettings.printerType]),
          copies: +formDataMainSettings[FormInputNameToMainSettings.copies],
          reprint: convertToBoolean(formDataMainSettings[FormInputNameToMainSettings.reprint]),
          printBatchNumber: convertToBoolean(
            formDataMainSettings[FormInputNameToMainSettings.printBatchNumber],
          ),
          observation: formDataMainSettings[FormInputNameToMainSettings.observation],
          batchs: payloadBatchs,
        };
        if (formDataMainSettings[FormInputNameToMainSettings.printerServerId]) {
          payload.printerServer = {
            id: formDataMainSettings[FormInputNameToMainSettings.printerServerId],
          } as PrinterServer;
        }
        const response = await api.post(`/event/ticket/${params.id}/main-settings`, payload);

        if (response && isBntNext) {
          nextTab();
        }
        if (!isBntNext) {
          reloadTickets();
        }
        if (response) toast.success('Dados salvos com sucesso!');
        ticketStates.setTicket({ ...ticketStates.ticket, ...response.data });
        ticketStep.setTicketState({ ...ticketStates.ticket, ...response.data });
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setState(States.default);
    }
  };

  const handleOnAddBatch = async (): Promise<void> => {
    try {
      setState(States.loading);
      if (isFormValidBatchs()) {
        const payloadStartData = dayjs(
          `${formDataBatchs[FormInputNameToBatch.startDate]}T${
            formDataBatchs[FormInputNameToBatch.startTime]
          }`,
        ).format('YYYY-MM-DDTHH:mm');

        const payloadEndData = dayjs(
          `${formDataBatchs[FormInputNameToBatch.endDate]}T${
            formDataBatchs[FormInputNameToBatch.endTime]
          }`,
        ).format('YYYY-MM-DDTHH:mm');

        const payload: any = {
          name: formDataBatchs[FormInputNameToBatch.name],
          startDate: payloadStartData,
          endDate: payloadEndData,
          // commission: +unmask(formDataBatchs[FormInputNameToBatch.commission]),
          amount: +formDataBatchs[FormInputNameToBatch.amount],
          unitValue: formDataBatchs[FormInputNameToBatch.unitValue],
          totalValue: formDataBatchs[FormInputNameToBatch.totalValue],
          percentageHalfPrice: formDataBatchs[FormInputNameToBatch.percentageHalfPrice]
            ? +unmask(formDataBatchs[FormInputNameToBatch.percentageHalfPrice])
            : undefined,
          amountHalfPrice: formDataBatchs[FormInputNameToBatch.amountHalfPrice],
          imageUrl: formDataBatchs[FormInputNameToBatch.imageUrl],
        };

        // dont add batch if already exists
        const batchExists = batchList.find(
          (batch: { name: string }) => batch.name === payload.name,
        );

        if (!batchExists) {
          const newBatchList: TicketBatch[] = [];
          newBatchList.push(payload);
          batchList.forEach(data => {
            newBatchList.push(data);
          });
          setBatchList(newBatchList);
          onChangeFormInputBatchs(FormInputNameToBatch.name)('');
          resetFormBatchs();
          toast.success('Lote adicionado com sucesso!');
        } else {
          toast.error('Lote com o mesmo nome já existe');
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setTimeout(() => {
        setState(States.default);
      }, 500);
    }
  };

  const handleOnDeleteBatch = async (batchSelected: TicketBatch): Promise<void> => {
    try {
      // delete batch from list
      // eslint-disable-next-line no-shadow
      const newBatchList = batchList.filter(
        (batch: TicketBatch) => batch.name !== batchSelected?.name,
      );

      setBatchList(newBatchList);
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnGetBatch = async (batchSelected: TicketBatch): Promise<void> => {
    try {
      setBatch(batchSelected);
      if (batchSelected.imageUrl) {
        // genetare string witch 10 random numbers
        const randomNumbers = (): string => {
          let result = '';
          const characters = '0123456789';
          const charactersLength = characters.length;
          for (let i = 0; i < 10; i += 1) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
          return result;
        };

        setFormNameFiles({
          ...formNameFiles,
          [FormInputNameToBatch.imageUrl]: `${randomNumbers()}.JPEG`,
        });
      } else {
        setFormNameFiles({
          ...formNameFiles,
          [FormInputNameToBatch.imageUrl]: 'Nenhum arquivo selecionado',
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnEditBatch = async (batchSelected: TicketBatch): Promise<void> => {
    try {
      if (isFormValidBatchs()) {
        const newBatchList: any = batchList.map((batch: TicketBatch) => {
          const payloadStartData = dayjs(
            `${formDataBatchs[FormInputNameToBatch.startDate]}T${
              formDataBatchs[FormInputNameToBatch.startTime]
            }`,
          ).format('YYYY-MM-DDTHH:mm');

          const payloadEndData = dayjs(
            `${formDataBatchs[FormInputNameToBatch.endDate]}T${
              formDataBatchs[FormInputNameToBatch.endTime]
            }`,
          ).format('YYYY-MM-DDTHH:mm');
          if (batch.name === batchSelected?.name) {
            return {
              ...batch,
              id: batchSelected?.id,
              name: formDataBatchs[FormInputNameToBatch.name],
              startDate: payloadStartData,
              endDate: payloadEndData,
              // commission: +unmask(formDataBatchs[FormInputNameToBatch.commission]),
              amount: +formDataBatchs[FormInputNameToBatch.amount],
              unitValue: formDataBatchs[FormInputNameToBatch.unitValue],
              totalValue: formDataBatchs[FormInputNameToBatch.totalValue],
              percentageHalfPrice: formDataBatchs[FormInputNameToBatch.percentageHalfPrice]
                ? +unmask(formDataBatchs[FormInputNameToBatch.percentageHalfPrice])
                : undefined,
              amountHalfPrice: formDataBatchs[FormInputNameToBatch.amountHalfPrice],
              imageUrl: formDataBatchs[FormInputNameToBatch.imageUrl],
            };
          }

          return batch;
        });
        setBatchList(newBatchList);
        setBatch(undefined);
        setFormNameFiles({ ...formNameFiles, [FormInputNameToBatch.imageUrl]: '' });
        resetFormBatchs();
        toast.success('Lote editado com sucesso.');
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnCancelEditBatch = async (): Promise<void> => {
    try {
      setBatch(undefined);
      setFormNameFiles({ ...formNameFiles, [FormInputNameToBatch.imageUrl]: '' });
      resetFormBatchs();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  // Config Modal --------------------------------------------------------------
  const handleOnShouldShowModal = ({
    value,
    newTitleModal,
    sector: sectorSelected,
  }: onShouldShowModalTicketMainSettingsProps): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitleModal);
    onToggle();

    if (sectorSelected?.id && value === ShouldShowModal.sector) {
      setSector(sectorSelected);
    }
  };

  const controllerModalConfig: modalConfigTicketMainSettingsProps = {
    title,
    visible,
    onChangeTitle,
    onToggle,
    onShouldShowModal: handleOnShouldShowModal,
    shouldShowModal,
  };
  // Config Modal --------------------------------------------------------------

  const controllerFormMainSettings: formMainSettingsProps = {
    formData: formDataMainSettings,
    formErrors: formErrorsMainSettings,
    isFormValid: isFormValidMainSettings,
    onChangeFormInput: onChangeFormInputMainSettings,
    onChangeFormFileInput: handleOnChangeFileInputMainSettings,
    formNameFiles,
  };

  const controllerFormBatchs: formBatchsProps = {
    formData: formDataBatchs,
    formErrors: formErrorsBatchs,
    onChangeFormInput: onChangeFormInputBatchs,
    onChangeFormFileInput: handleOnChangeFileInputBatch,
    formNameFiles,
  };

  const controllerFormSector: formSectorProps = {
    formData: formDataSector,
    formErrors: formErrorsSector,
    onChangeFormInput: onChangeFormInputSector,
  };

  const controllerSectorStates: sectorStatesProps = {
    sector,
    setSector,
    sectorList,
    setSectorList,
  };

  const controllerBatchsStates: batchStatesProps = {
    batch,
    setBatch,
    batchList,
    setBatchList,
  };

  const controllerMainSettingsActions: mainSettingsProps = {
    onSave: () => handleOnSaveMainSettings({ isBntNext: false }),
    onNextTab: () => handleOnSaveMainSettings({ isBntNext: true }),
  };

  const controllerSectorActions: sectorActionsProps = {
    onSave: handleOnSaveSector,
  };

  const controllerBatchActions: batchActionsProps = {
    onAdd: handleOnAddBatch,
    onEdit: handleOnEditBatch,
    onCancelEdit: handleOnCancelEditBatch,
    onGet: handleOnGetBatch,
    onDelete: handleOnDeleteBatch,
  };

  useEffect(() => {
    handleFecthSectorList();
  }, []);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        resetFormSector();
        setSector(undefined);
      }, 500);
    }
  }, [visible]);

  useEffect(() => {
    const { ticket } = ticketStates;
    if (!ticket) {
      resetFormMainSettings();
      resetFormBatchs();
      setBatch(undefined);
      setBatchList([]);
      setFormNameFiles({});
      onFirstTab();
    }
  }, [ticketStates.ticket]);

  useEffect(() => {
    if (batch) {
      onChangeFormInputBatchs(FormInputNameToBatch.name)(batch.name);
      onChangeFormInputBatchs(FormInputNameToBatch.startDate)(
        dayjs(batch?.startDate).format('YYYY-MM-DD') ?? '',
      );
      onChangeFormInputBatchs(FormInputNameToBatch.endDate)(
        dayjs(batch?.endDate).format('YYYY-MM-DD') ?? '',
      );
      onChangeFormInputBatchs(FormInputNameToBatch.startTime)(
        dayjs(batch?.startDate).format('HH:mm') ?? '',
      );
      onChangeFormInputBatchs(FormInputNameToBatch.endTime)(
        dayjs(batch?.endDate).format('HH:mm') ?? '',
      );
      /*
      onChangeFormInputBatchs(FormInputNameToBatch.commission)(
        String(toPercentage(batch.commission)),
      );
      */
      onChangeFormInputBatchs(FormInputNameToBatch.amount)(String(batch.amount));
      onChangeFormInputBatchs(FormInputNameToBatch.unitValue)(String(batch.unitValue));
      onChangeFormInputBatchs(FormInputNameToBatch.totalValue)(String(batch.totalValue));
      onChangeFormInputBatchs(FormInputNameToBatch.percentageHalfPrice)(
        String(toPercentage(batch.percentageHalfPrice)),
      );
      onChangeFormInputBatchs(FormInputNameToBatch.amountHalfPrice)(String(batch.amountHalfPrice));
      onChangeFormInputBatchs(FormInputNameToBatch.imageUrl)(String(batch.imageUrl ?? ''));
    }
  }, [batch]);

  const fromPrinterType = (printerType: PrinterType): string => {
    let value = '0';
    switch (printerType) {
      case PrinterType.MACHINE:
        value = '0';
        break;
      case PrinterType.BLUETOOTH:
        value = '1';
        break;
      case PrinterType.USB110:
        value = '2';
        break;
      case PrinterType.WHATSAPP:
        value = '3';
        break;
      case PrinterType.USB100:
        value = '4';
        break;
      default:
        printerType = PrinterType.MACHINE;
        break;
    }
    return value;
  };

  useEffect(() => {
    const { ticket } = ticketStates;

    if (ticket) {
      onChangeFormInputMainSettings(FormInputNameToMainSettings.name)(ticket.name);
      onChangeFormInputMainSettings(FormInputNameToMainSettings.eventSection)(
        ticket.eventSection.id,
      );
      onChangeFormInputMainSettings(FormInputNameToMainSettings.hasHalfPrice)(
        String(ticket.hasHalfPrice),
      );

      setHasHalfPrice(ticket.hasHalfPrice);

      /*
      onChangeFormInputMainSettings(FormInputNameToMainSettings.percentageHalfPrice)(
        toPercentage(ticket.percentageHalfPrice),
      );
      onChangeFormInputMainSettings(FormInputNameToMainSettings.amountHalfPrice)(
        String(ticket.amountHalfPrice),
      );
      */
      onChangeFormInputMainSettings(FormInputNameToMainSettings.hasCourtesy)(
        String(ticket.hasCourtesy),
      );
      onChangeFormInputMainSettings(FormInputNameToMainSettings.amountCourtesy)(
        String(ticket.amountCourtesy),
      );
      onChangeFormInputMainSettings(FormInputNameToMainSettings.printerType)(
        fromPrinterType(ticket.printerType),
      );
      if (ticket.printerServer) {
        onChangeFormInputMainSettings(FormInputNameToMainSettings.printerServerId)(
          ticket.printerServer.id,
        );
      }
      onChangeFormInputMainSettings(FormInputNameToMainSettings.copies)(String(ticket.copies));
      onChangeFormInputMainSettings(FormInputNameToMainSettings.reprint)(String(ticket.reprint));
      onChangeFormInputMainSettings(FormInputNameToMainSettings.printBatchNumber)(
        String(ticket.printBatchNumber),
      );
      onChangeFormInputMainSettings(FormInputNameToMainSettings.observation)(ticket.observation);

      setBatchList(
        ticket.batchs.map((item): any => ({
          id: item.id,
          name: item.name,
          startDate: item.startDate,
          endDate: item.endDate,
          commission: item.commission,
          amount: item.amount,
          unitValue: validators.applyDecimalMask(String(item.unitValue)),
          totalValue: validators.applyDecimalMask(String(item.totalValue)),
          percentageHalfPrice: item.percentageHalfPrice,
          amountHalfPrice: item.amountHalfPrice,
          imageUrl: item.imageUrl,
          amountSold: item.amountSold,
        })),
      );
    }
  }, [ticketStates.ticket]);

  useEffect(() => {
    if (sector?.id) {
      onChangeFormInputSector(FormInputNameToSector.name)(
        sectorList.find(item => item.id === sector.id)?.name ?? '',
      );
    }
  }, [sector]);

  return (
    <SectorTicketMainSettingsContainer
      state={state}
      ticketStates={ticketStates}
      printerServers={printerServers}
      formMainSettings={controllerFormMainSettings}
      formBatchs={controllerFormBatchs}
      formSector={controllerFormSector}
      sectorStates={controllerSectorStates}
      sectorActions={controllerSectorActions}
      mainSettingsActions={controllerMainSettingsActions}
      batchStates={controllerBatchsStates}
      batchActions={controllerBatchActions}
      modalConfig={controllerModalConfig}
      setHasHalfPrice={setHasHalfPrice}
      hasHalfPrice={hasHalfPrice}
    />
  );
};
