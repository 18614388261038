import { FormData, FormErrors, IsFormValid, OnChangeFormInput } from '@/hooks';
import EventMap from '@/model/EventMap';

export interface MapControler {
  formData: FormData;
  formErrors: FormErrors;
  onChangeFormInput: OnChangeFormInput;
  isFormValid: IsFormValid;
  resetForm: () => void;
}

// eslint-disable-next-line no-shadow
export enum FormInputName {
  secretWorkspaceKey = 'secretWorkspaceKey',
  publicWorkspaceKey = 'publicWorkspaceKey',
}

// eslint-disable-next-line no-shadow
export enum FormInputRegister {
  id = 'id',
  section = 'section',
  name = 'name',
}

// eslint-disable-next-line no-shadow
export enum FormInputTicket {
  id = 'id',
  ticket = 'ticket',
  eventKey = 'eventKey',
  mapName = 'mapName',
  hasTable = 'hasTable',
  seats = 'seats',
  bookableAsAWhole = 'bookableAsAWhole',
}

// eslint-disable-next-line no-shadow
export enum ShouldShowModal {
  map = 'map',
  confg = 'confg',
  ticket = 'ticket',
}

export interface OnShouldShowModalProps {
  value: ShouldShowModal;
  newTitleModal: string | React.ReactNode;
  eventMap?: EventMap;
  eventKey?: string;
}
