import React from 'react';

const IconDocument: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 98 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M94.1429 102.429C94.1429 104.626 93.2695 106.734 91.716 108.287C90.1624 109.841 88.0545 110.714 85.8571 110.714H11.2857C9.08818 110.714 6.98071 109.841 5.4268 108.287C3.87298 106.734 3 104.626 3 102.429V11.2857C3 9.08818 3.87298 6.98071 5.4268 5.42683C6.98071 3.87296 9.08818 3 11.2857 3H65.1429L94.1429 32V102.429Z"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61 3V36.1429H94.1429"
      stroke="white"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.8745 110.126L36.6491 74.3224C35.9899 73.7734 35.1592 73.4727 34.3013 73.4727C33.4435 73.4727 32.6127 73.7734 31.9535 74.3224L4 94.4256"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.7635 73.4727C62.1101 73.4727 57.5271 68.8898 57.5271 63.2364C57.5271 57.583 62.1101 53 67.7635 53C73.4168 53 78 57.583 78 63.2364C78 68.8898 73.4168 73.4727 67.7635 73.4727Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconDocument;
