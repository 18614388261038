import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Page from '@/model/Page';
import api, { AxiosError } from '@/services/api';
import Event from '@/model/Event';
import { States } from '../../types';
import { ReleaseEventUI } from './ui';

export const ReleaseEventScreen: React.FC = (): JSX.Element => {
  const [state, setState] = useState<States>(States.default);
  const [page, setPage] = useState<Page<Event, Event>>({
    page: 1,
    pageSize: 10,
    sort: 'startDate',
    order: 'DESC',
    total: 1,
  });

  const handleOnPage = async (pageData: Page<Event, Event>): Promise<void> => {
    try {
      setState(States.loading);
      const { data } = await api.post<Page<Event, Event>>('/event/page/release', pageData);
      if (data) {
        setPage(data);
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status !== 401) {
        toast.error(err.message);
      }
    } finally {
      setState(States.default);
    }
  };

  const handleOnPaginationChange = async (pageNumber: number): Promise<void> => {
    const newPage = {
      ...page,
      page: pageNumber,
    };
    await handleOnPage(newPage);
  };

  useEffect(() => {
    handleOnPage(page);
  }, []);
  return <ReleaseEventUI state={state} page={page} onPaginationChange={handleOnPaginationChange} />;
};
