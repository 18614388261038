import React from 'react';
import { Route } from '@/navigation/Route';
import { StatementScreen } from './screens';

export const STATEMENT_ROUTES = {
  itself: '/financial/statement',
};

export const StatementNavigation = (): JSX.Element => (
  <Route exact path={STATEMENT_ROUTES.itself} component={StatementScreen} isPrivateRoute />
);
