import { Button, Dialog, Loading } from '@/components';
import React, { Fragment, ReactNode } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import { colors } from '@/styles/colors';
import { CustomTable } from '@/components/Table';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { toPercentage } from '@/helpers/common/amount';
import ProducerReportType from '@/model/ProducerReportType';
import { ActionProps } from '@/components/Dialog';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import Contractor from '@/model/Contractor';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import dayjs from 'dayjs';
import EventMoneyTransferStatus from '@/model/EventMoneyTransferStatus';
import { ProducerReportMain } from '@/model/ProducerReportMain';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import { ReactComponent as Download } from '@/assets/images/svg/download.svg';
import { unmask } from '@/helpers/masks/cashNumber';
import { FormInput, ShouldShowModal, States, transferColumns } from '../../types';
import { TransferContent } from '../../components/TransferContent';
import { DetailContent } from '../../components/DetailContent';

interface StateProps {
  state: States;
  producerReportMain?: ProducerReportMain;
  transfers: EventMoneyTransfer[];
  title: string | ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  formData: FormData;
  formErrors: FormErrors;
  contractors: Contractor[];
  eventMoneyTransfer?: EventMoneyTransfer;
}

interface DispatchProps {
  onToggle: () => void;
  onChangeFormInput: OnChangeFormInput;
  onTransfer: () => void;
  onAdvance: () => void;
  onShouldShowModal: ({
    value,
    newTitleModal,
    selectedId,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    selectedId?: string;
  }) => void;
  onDownload: (itemUrl: string) => void;
}

type Props = StateProps & DispatchProps;

export const ProducerReportContainer: React.FC<Props> = ({
  state,
  producerReportMain,
  transfers,
  title,
  visible,
  onToggle,
  shouldShowModal,
  onTransfer,
  onAdvance,
  formData,
  formErrors,
  contractors,
  onChangeFormInput,
  onShouldShowModal,
  eventMoneyTransfer,
  onDownload,
}): JSX.Element => {
  const checkIfIsAdvance = (): boolean => {
    let isAdvance = false;
    if (formData[FormInput.contractor] && contractors && contractors.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < contractors.length; i++) {
        if (contractors[i].id === formData[FormInput.contractor]) {
          if (contractors[i].advance) {
            if (formData[FormInput.transferValue]) {
              if (producerReportMain && producerReportMain.summary) {
                const transferValue = Number(unmask(formData[FormInput.transferValue]));
                if (transferValue > producerReportMain.summary.balance) {
                  isAdvance = true;
                }
              }
            }
          }
          break;
        }
      }
    }
    return isAdvance;
  };
  const renderActionDialogToCancel: ActionProps = {
    title: 'Cancelar',
    onClick: (): void => onToggle(),
    theme: 'noneBorder',
  };

  const getTransferStatus = (status: EventMoneyTransferStatus): string => {
    let value = '';
    if (status === EventMoneyTransferStatus.WAITING) {
      value = 'Aguardando';
    } else if (status === EventMoneyTransferStatus.TRANSFERRED) {
      value = 'Transferindo';
    } else if (status === EventMoneyTransferStatus.REFUSED) {
      value = 'Recusado';
    }
    return value;
  };

  const getTransferDate = (transfer: EventMoneyTransfer): string => {
    let date = '---';
    if (transfer.transferStatus === EventMoneyTransferStatus.TRANSFERRED && transfer.transferDate) {
      date = dayjs(transfer.transferDate).format('DD/MM/YYYY HH:mm:ss');
    } else if (
      transfer.transferStatus === EventMoneyTransferStatus.REFUSED &&
      transfer.refusedDate
    ) {
      date = dayjs(transfer.refusedDate).format('DD/MM/YYYY HH:mm:ss');
    }
    return date;
  };

  const dataTableTransfers =
    transfers && transfers.length > 0
      ? transfers.map(item => ({
          contractor: item.contractor.name,
          bank: item.pixKey.bank.fullName,
          pixKey: item.pixKey.key,
          contractorUser: item.contractorUser.name,
          transferValue: toCurrency(item.transferValue),
          transferRequestDate: dayjs(item.transferRequestDate).format('DD/MM/YYYY HH:mm:ss'),
          transferred: getTransferStatus(item.transferStatus),
          transferDate: getTransferDate(item),
          action: (
            <React.Fragment>
              {((item.transferStatus === EventMoneyTransferStatus.TRANSFERRED &&
                item.transferDate) ||
                (item.transferStatus === EventMoneyTransferStatus.REFUSED && item.refusedDate)) && (
                <Detail
                  className={'mr-4 svg-icon action-icon'}
                  onClick={() =>
                    onShouldShowModal({
                      value: ShouldShowModal.detail,
                      newTitleModal: 'Pedido',
                      selectedId: item.id,
                    })
                  }
                />
              )}
              {item.transferStatus === EventMoneyTransferStatus.TRANSFERRED &&
                item.transferDate &&
                item.attachmentUrl && (
                  <Download
                    title="Resgatar"
                    className={'mr-4 svg-icon-error action-icon'}
                    onClick={() => onDownload(item.attachmentUrl as string)}
                  />
                )}
            </React.Fragment>
          ),
        }))
      : [];

  return (
    <Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.transfer]: renderActionDialogToCancel,
            [ShouldShowModal.detail]: renderActionDialogToCancel,
          }[shouldShowModal],
          {
            [ShouldShowModal.transfer]: {
              title: checkIfIsAdvance() ? 'Solicitar Advance' : 'Solicitar transferência',
              onClick: () => (checkIfIsAdvance() ? onAdvance() : onTransfer()),
              disabled: Object.keys(formErrors).length === 0 && formErrors.constructor === Object,
            },
            [ShouldShowModal.detail]: {},
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.transfer]: (
              <TransferContent
                formData={formData}
                formErrors={formErrors}
                contractors={contractors}
                onChangeFormInput={onChangeFormInput}
              />
            ),
            [ShouldShowModal.detail]: <DetailContent data={eventMoneyTransfer} />,
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between mb-5">
          <div className="pageTitle d-flex">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <ArrowLeft color={colors.black} className="arrow-left" />
            </Link>
            <span className="ml-3 mb-0 mt-2">Lançamentos</span>
          </div>
          <Button
            title="Resgatar valor"
            onClick={(): void =>
              onShouldShowModal({
                value: ShouldShowModal.transfer,
                newTitleModal: 'Resgatar valor',
              })
            }
          />
        </div>
        <h5>Totalizadores</h5>
        {producerReportMain && producerReportMain.summary && (
          <Fragment>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: '10px',
                padding: '20px 10px 10px 10px',
                display: 'inline-block',
                width: '100%',
                maxWidth: '600px',
                marginBottom: '20px',
                background: '#fff',
                boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Row
                className="mb-4"
                style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col md={6}>
                  <div className="text-darkgray-regular">Valor total arrecadado:</div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <div className="text-success">
                    {toCurrency(producerReportMain.summary.totalSale)}
                  </div>
                </Col>
              </Row>
              <Row
                className="mb-4"
                style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col xs={6}>
                  <div className="text-darkgray-regular">Dinheiro:</div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right', borderBottom: '1px' }}>
                  <div className="text-danger">{toCurrency(producerReportMain.summary.money)}</div>
                </Col>
              </Row>
              <Row
                className="mb-4"
                style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col xs={6}>
                  <div className="text-darkgray-regular">Vouchers/Descontos:</div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right', borderBottom: '1px' }}>
                  <div className="text-danger">
                    {toCurrency(producerReportMain.summary.vouchers)}
                  </div>
                </Col>
              </Row>
              <Row
                className="mb-4"
                style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col xs={6}>
                  <div className="text-darkgray-regular">Despesas:</div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right', borderBottom: '1px' }}>
                  <div className="text-danger">
                    {toCurrency(producerReportMain.summary.discounts)}
                  </div>
                </Col>
              </Row>
              <Row
                className="mb-4"
                style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col xs={6}>
                  <div className="text-darkgray-regular">Resgates:</div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right', borderBottom: '1px' }}>
                  <div className="text-danger">
                    {toCurrency(producerReportMain.summary.transfers)}
                  </div>
                </Col>
              </Row>
              <Row
                className="mb-4"
                // style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col xs={6}>
                  <div
                    className="text-darkgray-regular"
                    style={{ fontWeight: 'bold', letterSpacing: '1px' }}
                  >
                    Saldo:
                  </div>
                </Col>
                <Col
                  xs={6}
                  style={{
                    textAlign: 'right',
                    borderBottom: '1px',
                    fontWeight: 'bold',
                    letterSpacing: '1px',
                  }}
                >
                  <div
                    className={
                      producerReportMain.summary.balance > 0 ? 'text-success' : 'text-danger'
                    }
                  >
                    {toCurrency(producerReportMain.summary.balance)}
                  </div>
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
        <hr className="dividerUp" />
        {transfers && transfers.length > 0 && (
          <Fragment>
            <h5>Resgates</h5>
            <CustomTable
              columns={transferColumns}
              data={dataTableTransfers}
              numberRowsPerPage={1000}
              progressPending={state === States.loading}
              theme="primary"
            />
          </Fragment>
        )}
        {producerReportMain && producerReportMain.ticket && producerReportMain.ticket.summary && (
          <Fragment>
            <h6 className="mb-4 collapseTableText">Ingressos</h6>
            <Card
              className="mb-5 card-no-border customTableScroll"
              style={{ border: 'none', overflow: 'auto' }}
            >
              <div className="p-4">
                <div className="collapseTableText subTitleMain">
                  <label>Receitas</label>
                </div>
                <CustomTable
                  theme="producerTheme"
                  numberRowsPerPage={0}
                  progressPending={false}
                  columns={[
                    {
                      name: 'Descrição',
                      selector: row => row.description,
                    },
                    {
                      name: 'Valor',
                      selector: row => row.baseValue,
                    },
                  ]}
                  data={
                    producerReportMain.ticket.incomes &&
                    producerReportMain.ticket.incomes.length > 0
                      ? producerReportMain.ticket.incomes.map(item => ({
                          description: item.description,
                          baseValue: (
                            <label
                              style={{
                                color:
                                  item.type === ProducerReportType.DEBIT ? '#e64f49' : '#266f2c',
                              }}
                            >
                              {toCurrency(item.baseValue)}
                            </label>
                          ),
                        }))
                      : []
                  }
                />
              </div>
              <hr className="mb-0" style={{ minWidth: '1080px' }} />
              <div
                style={{
                  padding: '30px 40px',
                  gap: '40px',
                }}
                className="d-flex justify-content-center"
              >
                <>
                  <div className="d-flex">
                    <div className="mr-1 text-small-gray-regular">Total:</div>
                    <div className="text-small-black-600">{`${toCurrency(
                      producerReportMain.ticket.summary.totalSale,
                    )}`}</div>
                  </div>
                </>
              </div>
            </Card>
            <Card
              className="mb-5 card-no-border customTableScroll"
              style={{ border: 'none', overflow: 'auto' }}
            >
              <div className="p-4">
                <div className="collapseTableText subTitleMain">
                  <label>Despesas</label>
                </div>
                <CustomTable
                  theme="producerTheme"
                  numberRowsPerPage={0}
                  progressPending={false}
                  columns={[
                    {
                      name: 'Descrição',
                      selector: row => row.description,
                    },
                    {
                      name: 'Valor base',
                      selector: row => row.baseValue,
                    },
                    {
                      name: 'Taxa',
                      selector: row => row.fee,
                    },
                    {
                      name: 'Valor',
                      selector: row => row.value,
                    },
                  ]}
                  data={
                    producerReportMain.ticket.expenses &&
                    producerReportMain.ticket.expenses.length > 0
                      ? producerReportMain.ticket.expenses.map(item => ({
                          description: item.description,
                          baseValue:
                            item.description === 'Boleto' ||
                            item.description === 'Aluguel equipamento'
                              ? // eslint-disable-next-line radix
                                parseInt(`${item.baseValue}`)
                              : toCurrency(item.baseValue),
                          // eslint-disable-next-line no-nested-ternary
                          fee: item.fee
                            ? item.description === 'Boleto' ||
                              item.description === 'Aluguel equipamento'
                              ? toCurrency(item.fee)
                              : `${toPercentage(item.fee)} %`
                            : 'N/A',
                          value: (
                            <label
                              style={{
                                color:
                                  item.type === ProducerReportType.DEBIT ? '#e64f49' : '#3caec8',
                              }}
                            >
                              {toCurrency(item.value)}
                            </label>
                          ),
                        }))
                      : []
                  }
                />
              </div>
              <hr className="mb-0" style={{ minWidth: '1080px' }} />
              <div
                style={{
                  padding: '30px 40px',
                  gap: '40px',
                }}
                className="d-flex justify-content-center"
              >
                <>
                  <div className="d-flex">
                    <div className="mr-1 text-small-gray-regular">Total:</div>
                    <div className="text-small-black-600">{`${toCurrency(
                      producerReportMain.ticket.summary.discounts,
                    )}`}</div>
                  </div>
                </>
              </div>
            </Card>
          </Fragment>
        )}

        {producerReportMain && producerReportMain.product && producerReportMain.product.summary && (
          <Fragment>
            <h6 className="mb-4 collapseTableText">Bar</h6>
            <Card
              className="mb-5 card-no-border customTableScroll"
              style={{ border: 'none', overflow: 'auto' }}
            >
              <div className="p-4">
                <div className="collapseTableText subTitleMain">
                  <label>Receitas</label>
                </div>
                <CustomTable
                  theme="producerTheme"
                  numberRowsPerPage={0}
                  progressPending={false}
                  columns={[
                    {
                      name: 'Descrição',
                      selector: row => row.description,
                    },
                    {
                      name: 'Valor',
                      selector: row => row.baseValue,
                    },
                  ]}
                  data={
                    producerReportMain.product.incomes &&
                    producerReportMain.product.incomes.length > 0
                      ? producerReportMain.product.incomes.map(item => ({
                          description: item.description,
                          baseValue: (
                            <label
                              style={{
                                color:
                                  item.type === ProducerReportType.DEBIT ? '#e64f49' : '#266f2c',
                              }}
                            >
                              {toCurrency(item.baseValue)}
                            </label>
                          ),
                        }))
                      : []
                  }
                />
              </div>
              <hr className="mb-0" style={{ minWidth: '1080px' }} />
              <div
                style={{
                  padding: '30px 40px',
                  gap: '40px',
                }}
                className="d-flex justify-content-center"
              >
                <>
                  <div className="d-flex">
                    <div className="mr-1 text-small-gray-regular">Total:</div>
                    <div className="text-small-black-600">{`${toCurrency(
                      producerReportMain.product.summary.totalSale,
                    )}`}</div>
                  </div>
                </>
              </div>
            </Card>
            <Card
              className="mb-5 card-no-border customTableScroll"
              style={{ border: 'none', overflow: 'auto' }}
            >
              <div className="p-4">
                <div className="collapseTableText subTitleMain">
                  <label>Despesas</label>
                </div>
                <CustomTable
                  theme="producerTheme"
                  numberRowsPerPage={0}
                  progressPending={false}
                  columns={[
                    {
                      name: 'Descrição',
                      selector: row => row.description,
                    },
                    {
                      name: 'Valor base',
                      selector: row => row.baseValue,
                    },
                    {
                      name: 'Taxa',
                      selector: row => row.fee,
                    },
                    {
                      name: 'Valor',
                      selector: row => row.value,
                    },
                  ]}
                  data={
                    producerReportMain.product.expenses &&
                    producerReportMain.product.expenses.length > 0
                      ? producerReportMain.product.expenses.map(item => ({
                          description: item.description,
                          baseValue:
                            item.description === 'Boleto' ||
                            item.description === 'Aluguel equipamento'
                              ? // eslint-disable-next-line radix
                                parseInt(`${item.baseValue}`)
                              : toCurrency(item.baseValue),
                          // eslint-disable-next-line no-nested-ternary
                          fee: item.fee
                            ? item.description === 'Boleto' ||
                              item.description === 'Aluguel equipamento'
                              ? toCurrency(item.fee)
                              : `${toPercentage(item.fee)} %`
                            : 'N/A',
                          value: (
                            <label
                              style={{
                                color:
                                  item.type === ProducerReportType.DEBIT ? '#e64f49' : '#3caec8',
                              }}
                            >
                              {toCurrency(item.value)}
                            </label>
                          ),
                        }))
                      : []
                  }
                />
              </div>
              <hr className="mb-0" style={{ minWidth: '1080px' }} />
              <div
                style={{
                  padding: '30px 40px',
                  gap: '40px',
                }}
                className="d-flex justify-content-center"
              >
                <>
                  <div className="d-flex">
                    <div className="mr-1 text-small-gray-regular">Total:</div>
                    <div className="text-small-black-600">{`${toCurrency(
                      producerReportMain.product.summary.discounts,
                    )}`}</div>
                  </div>
                </>
              </div>
            </Card>
          </Fragment>
        )}

        {producerReportMain &&
          producerReportMain.general &&
          producerReportMain.general.expenses &&
          producerReportMain.general.expenses.length > 0 && (
            <Fragment>
              <h6 className="mb-4 collapseTableText">Despesas gerais</h6>
              <Card
                className="mb-5 card-no-border customTableScroll"
                style={{ border: 'none', overflow: 'auto' }}
              >
                <div className="p-4">
                  <CustomTable
                    theme="producerTheme"
                    numberRowsPerPage={0}
                    progressPending={false}
                    columns={[
                      {
                        name: 'Descrição',
                        selector: row => row.description,
                      },
                      {
                        name: 'Valor',
                        selector: row => row.baseValue,
                      },
                    ]}
                    data={producerReportMain.general.expenses.map(item => ({
                      description: item.description,
                      baseValue: (
                        <label
                          style={{
                            color: item.type === ProducerReportType.DEBIT ? '#e64f49' : '#266f2c',
                          }}
                        >
                          {toCurrency(item.baseValue)}
                        </label>
                      ),
                    }))}
                  />
                </div>
                <hr className="mb-0" style={{ minWidth: '1080px' }} />
                <div
                  style={{
                    padding: '30px 40px',
                    gap: '40px',
                  }}
                  className="d-flex justify-content-center"
                >
                  <>
                    <div className="d-flex">
                      <div className="mr-1 text-small-gray-regular">Total:</div>
                      <div className="text-small-black-600">{`${toCurrency(
                        producerReportMain.general.summary.discounts,
                      )}`}</div>
                    </div>
                  </>
                </div>
              </Card>
            </Fragment>
          )}
      </Container>
    </Fragment>
  );
};
