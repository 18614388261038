import { States } from '@/helpers/common/states';
import { StatementResponseData } from '@/model/StatementResponseData';
import React, { Fragment, useEffect, useState } from 'react';
import { FormData, FormErrors, OnChangeFormInput } from '@/hooks';
import { ActionProps, Dialog } from '@/components/Dialog';
import { Loading } from '@/components';
import { Col, Container, Row } from 'reactstrap';
import FilterVector from '@/assets/images/svg/FilterVector';
import { CustomTable } from '@/components/Table';
import StatementType from '@/model/StatementType';
import dayjs from 'dayjs';
import { toCurrency } from '@/helpers/masks/toCurrency';
import { ReactComponent as Detail } from '@/assets/images/svg/detail.svg';
import Event from '@/model/Event';
import { EventMoneyTransfer } from '@/model/EventMoneyTransfer';
import { EventCloseExpense } from '@/model/EventCloseExpense';
import { columns, ShouldShowModal } from '../../types';
import { FilterContent } from '../../components/FilterContent';
import { DetailRequestContent } from '../../components/DetailRequestContent';
import { DetailManualContent } from '../../components/DetailManualContent';

interface StateProps {
  state: States;
  statements: StatementResponseData[];
  title: string | React.ReactNode;
  visible: boolean;
  shouldShowModal: ShouldShowModal;
  formDataFilter: FormData;
  formErrorsFilter: FormErrors;
  events: Event[];
  eventMoneyTransfer?: EventMoneyTransfer;
  eventCloseExpense?: EventCloseExpense;
}

interface DispatchProps {
  onShouldShowModal: (
    value: ShouldShowModal,
    newTitleModal: string | React.ReactNode,
    selectedId?: string,
    type?: StatementType,
    eventId?: string,
  ) => void;
  onToggle: () => void;
  onChangeFormInputFilter: OnChangeFormInput;
  onFilter: () => void;
  onClearFilter: () => void;
  onCancelDetail: () => void;
}

type Props = StateProps & DispatchProps;

export const StatementSContainer: React.FC<Props> = ({
  state,
  statements,
  onShouldShowModal,
  title,
  visible,
  onToggle,
  shouldShowModal,
  formDataFilter,
  formErrorsFilter,
  onChangeFormInputFilter,
  events,
  onFilter,
  onClearFilter,
  onCancelDetail,
  eventMoneyTransfer,
  eventCloseExpense,
}): JSX.Element => {
  const [request, setRequest] = useState<number>(0);
  const [manual, setManual] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const renderActionDialogToFilter: ActionProps = {
    title: 'Limpar',
    onClick: (): void => onClearFilter(),
    theme: 'noneBorder',
  };

  const renderActionDialogToCancel: ActionProps = {
    title: 'Fechar',
    onClick: (): void => onCancelDetail(),
    theme: 'noneBorder',
  };

  const getStatementType = (type: StatementType): string => {
    let value = '';
    if (type === StatementType.MANUAL) {
      value = 'Lançamento';
    } else if (type === StatementType.REQUEST) {
      value = 'Resgate';
    }
    return value;
  };

  const dataTable =
    statements && statements.length > 0
      ? statements.map(data => ({
          id: data.id,
          eventName: data.eventName,
          description: data.description,
          type: getStatementType(data.type),
          date: dayjs(data.date).format('DD/MM/YYYY [às] HH:mm'),
          value: toCurrency(data.value),
          actions: (
            <Detail
              className={'mr-4 svg-icon action-icon'}
              onClick={() =>
                onShouldShowModal(
                  data.type === StatementType.REQUEST
                    ? ShouldShowModal.detailRequest
                    : ShouldShowModal.detailManual,
                  data.type === StatementType.REQUEST ? 'Resgate' : 'Lançamento',
                  data.id,
                  data.type,
                  data.eventId,
                )
              }
            />
          ),
        }))
      : [];

  const handleOnCalc = (): void => {
    let newRequest = 0;
    let newManual = 0;
    statements.forEach(data => {
      if (data.type === StatementType.REQUEST) {
        newRequest += Number(data.value);
      } else if (data.type === StatementType.MANUAL) {
        newManual += Number(data.value);
      }
    });
    setRequest(newRequest);
    setManual(newManual);
    setTotal(newRequest + newManual);
  };

  useEffect(() => {
    handleOnCalc();
  }, [statements]);
  return (
    <React.Fragment>
      <Loading isVisible={state === States.loading} />
      <Dialog
        title={title}
        visible={visible && state === States.default}
        onClose={onToggle}
        position={'center'}
        isContentWithCard={true}
        actions={[
          {
            [ShouldShowModal.filter]: renderActionDialogToFilter,
            [ShouldShowModal.detailRequest]: renderActionDialogToCancel,
            [ShouldShowModal.detailManual]: renderActionDialogToCancel,
          }[shouldShowModal],
          {
            [ShouldShowModal.filter]: {
              title: 'Filtrar',
              onClick: () => onFilter(),
            },
            [ShouldShowModal.detailRequest]: {
              title: 'Detalhe',
            },
            [ShouldShowModal.detailManual]: {
              title: 'Detalhe',
            },
          }[shouldShowModal],
        ]}
      >
        {
          {
            [ShouldShowModal.filter]: (
              <FilterContent
                formData={formDataFilter}
                formErrors={formErrorsFilter}
                onChangeFormInput={onChangeFormInputFilter}
                events={events}
              />
            ),
            [ShouldShowModal.detailRequest]: <DetailRequestContent data={eventMoneyTransfer} />,
            [ShouldShowModal.detailManual]: <DetailManualContent data={eventCloseExpense} />,
          }[shouldShowModal]
        }
      </Dialog>
      <Container className="mainContainer" fluid={true}>
        <div className="d-flex justify-content-between" style={{ paddingBottom: '30px' }}>
          <h5 className="pageTitle">Extrato de lançamentos</h5>
          <div className="button-filter-container">
            <div className="filter-container">
              <div
                className="filter-content"
                onClick={(): void => {
                  onShouldShowModal(ShouldShowModal.filter, 'Filtrar');
                }}
              >
                <FilterVector />
              </div>
            </div>
          </div>
        </div>
        <h5>Totalizadores</h5>
        {statements && statements.length && (
          <Fragment>
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: '10px',
                padding: '20px 10px 10px 10px',
                display: 'inline-block',
                width: '100%',
                maxWidth: '600px',
                marginBottom: '20px',
                background: '#fff',
                boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Row
                className="mb-4"
                style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col md={6}>
                  <div className="text-darkgray-regular">Resgates:</div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <div className="text-success">{toCurrency(request)}</div>
                </Col>
              </Row>
              <Row
                className="mb-4"
                style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col md={6}>
                  <div className="text-darkgray-regular">Lançamentos manuais:</div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <div className="text-success">{toCurrency(manual)}</div>
                </Col>
              </Row>
              <Row
                className="mb-4"
                style={{ borderBottom: '1px solid #ccc', paddingBottom: '5px' }}
              >
                <Col md={6}>
                  <div className="text-darkgray-regular">Total:</div>
                </Col>
                <Col xs={6} style={{ textAlign: 'right' }}>
                  <div className="text-success">{toCurrency(total)}</div>
                </Col>
              </Row>
            </div>
          </Fragment>
        )}
        <CustomTable
          theme="producerTheme"
          columns={columns}
          data={dataTable}
          numberRowsPerPage={0}
          progressPending={state === States.loading}
        />
      </Container>
    </React.Fragment>
  );
};
